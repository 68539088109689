import React, { useEffect, useState } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Outlet, useLocation } from 'react-router-dom';
import { DASHBOARD } from '@app/components/router/AppRouter';
import { useResponsive } from '@app/hooks/useResponsive';
import { References } from '@app/components/common/References/References';

const MainLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop, idMobileNew } = useResponsive();
  const location = useLocation();

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  useEffect(() => {
    setIsTwoColumnsLayout([DASHBOARD].includes(location.pathname) && isDesktop);
  }, [location.pathname, isDesktop]);

  return (
    <>
      <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
        <Header
          isCollapsed={siderCollapsed}
          toggleSider={toggleSider}
          isSiderOpened={!siderCollapsed}
          isTwoColumnsLayout={isTwoColumnsLayout}
        />
      </MainHeader>
      <S.LayoutMaster>
        <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
        <S.LayoutMain>
          <MainContent
            id="main-content"
            $isTwoColumnsLayout={isTwoColumnsLayout}
            style={{ marginLeft: !siderCollapsed ? '260px' : idMobileNew ? '0px' : '100px' }}
          >
            <div>
              <Outlet />
            </div>
            {!isTwoColumnsLayout && <References />}
          </MainContent>
        </S.LayoutMain>
      </S.LayoutMaster>
    </>
  );
};

export default MainLayout;
