/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import * as S from './PopupModel.style';
interface ModalProps {
  open: boolean;
  setIsModalOpen: (any: boolean) => void;
  content: any;
}

const PopupModal: React.FC<ModalProps> = ({ open, setIsModalOpen, content }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <S.PopupModals maskClosable={false} open={open} onOk={handleOk} onCancel={handleCancel} footer={null}>
        {content}
      </S.PopupModals>
    </>
  );
};

export default PopupModal;
