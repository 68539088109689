/* eslint-disable @typescript-eslint/no-explicit-any */
import { httpApi } from '@app/api/http.api';
// import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  password: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  data: any;
  token: string;
  user: UserModel;
}
const currentUrl = window.location.href;

// Parse the URL
const url = new URL(currentUrl);

// Get the values of userId and Token from the query parameters
const userId = url.searchParams.get('userId');
const token = url.searchParams.get('token');
export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<LoginResponse>('authentication', { ...loginPayload }).then(({ data }) => data);
export const logout = (): Promise<LoginResponse> =>
  httpApi.post<LoginResponse>('authentication/logout').then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('sign-up/1', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('passwordReset', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (): Promise<undefined> =>
  httpApi.post<undefined>(`sign-up/verifyMail/${userId}`).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>(`newPassword/${userId}/${token}`, { ...newPasswordData }).then(({ data }) => data);
