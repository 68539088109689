import styled from 'styled-components';
import { FONT_SIZE } from '@app/styles/themes/constants';
import { BaseMenu } from '@app/components/common/BaseMenu/BaseMenu';

export const Menu = styled(BaseMenu)`
  background: transparent;
  border-right: 0;
  padding: 10px;
  a {
    width: 100%;
    display: block;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    font-size: ${FONT_SIZE.xs};
    margin-bottom: 20px !important;
  }

  .ant-menu-item-icon {
    width: 1.25rem;
  }
  .collapsedbyclick .ant-menu-item-icon {
    transition: 0.5s ease-in-out;
    transform: inherit;
  }
  .collapsedbyclick svg {
    transition: 0.5s ease-in-out;
    transform: inherit !important;
  }
  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow,
  span[role='img'],
  a,
  .ant-menu-item,
  .ant-menu-submenu {
    color: var(--text-sider-secondary-color);
    fill: var(--text-sider-secondary-color);
  }

  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover {
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    span[role='img'],
    a,
    .ant-menu-item-icon,
    .ant-menu-title-content {
      color: var(--text-sider-primary-color);
      fill: var(--text-sider-primary-color);
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: var(--text-sider-primary-color);

      .ant-menu-submenu-expand-icon,
      .ant-menu-submenu-arrow,
      span[role='img'] {
        color: var(--text-sider-primary-color);
        fill: var(--text-sider-primary-color);
      }
    }
  }

  .ant-menu-item-selected {
    background-color: transparent !important;

    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    span[role='img'],
    .ant-menu-item-icon,
    a {
      color: var(--text-sider-primary-color);
      fill: var(--text-sider-primary-color);
    }
  }

  .ant-menu-item-active,
  .ant-menu-submenu-active .ant-menu-submenu-title {
    background-color: #43736d !important;
    border-radius: 16px;
  }
  .ant-menu-item {
    svg {
      transition: 0.5s ease-in-out;
      transform: scale(2);
    }
  }
  .ant-menu-item.ant-menu-item-selected {
    background-color: #43736d !important;
    border-radius: 16px;
    transition: 0.5s ease-in-out;
    svg {
      transition: 0.5s ease-in-out;
      transform: scale(2) !important;
    }
  }
`;
