/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Form } from 'antd';
import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
// import { useAppDispatch } from '@app/hooks/reduxHooks';
// import { doLogin } from '@app/store/slices/authSlice';
// import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { Contactus } from '@app/api/ContactUs.api';
import { notificationController } from '@app/controllers/notificationController';
// import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
interface LoginFormData {
  email: string;
  password: string;
}

export const initValues: LoginFormData = {
  email: 'hello@altence.com',
  password: 'some-test-pass',
};
const ContactUs: React.FC = () => {
  const Container = styled.div`
    width: 50%;
    margin: 0 auto;
    text-align: center;
    @media (max-width: 540px) {
      width: 100%;
    }
  `;
  const Title = styled.h2`
    color: #3c3c3c;
    fontweight: 700px;
    font-size: 30px;
  `;
  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const { t } = useTranslation();
  // const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const handleSubmit = (values: any) => {
    const data: any = {
      type: 'help',
      detail: values.textArea,
    };
    Contactus(data)
      .then(() => {
        notificationController.success({
          message: 'Thank you for reaching out to us! Our support team will be in touch with you shortly.',
        });
        form.resetFields();
      })
      .catch((err: any) => {
        notificationController.error({ message: err.message });
      });
    // setLoading(true);
    // dispatch(doLogin(values))
    //   .unwrap()
    //   .then(() => navigate('/'))
    //   .catch((err) => {
    //     notificationController.error({ message: err.message });
    //     setLoading(false);
    //   });
  };
  const FormWrapper = styled(Auth.FormWrapper)`
    padding: 0;
    margin: 30px 0;
    width: 100%;
  `;

  const SubmitButton = styled(Auth.SubmitButton)`
    background: #72b114;
    color: white;
  `;
  return (
    <Container>
      <Title>Contact Us</Title>
      <p>We are here to assist you! Our dedicated support team will be in touch as soon as possible.</p>

      <FormWrapper>
        <BaseForm
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
        >
          <Auth.FormItem
            name="textArea"
            rules={[
              { required: true, message: t('common.requiredField') },
              {
                type: 'string',
                message: t('common.description'),
              },
            ]}
          >
            <Auth.FormInput.TextArea rows={5} placeholder="Please describe your issue or question." />
          </Auth.FormItem>
          <BaseForm.Item noStyle>
            <SubmitButton type="primary" htmlType="submit">
              Submit
            </SubmitButton>
          </BaseForm.Item>
        </BaseForm>
      </FormWrapper>
    </Container>
  );
};

export default ContactUs;
