import React, { useState } from 'react';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import styled from 'styled-components';
import { notificationController } from '@app/controllers/notificationController';
import { ActivityStory } from './activityStory/ActivityStory';
// import { ViewAvocadosUsers } from '@app/api/alluser/GetAllUser';
import axios from 'axios';
import { useAppSelector } from '@app/hooks/reduxHooks';
import PopupModal from '@app/components/Model/PopupModal';
import LogoChange from '../logoChange/LogoChangeModel';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import { useTranslation } from 'react-i18next';
const YourSubscriptionbutton = styled(BaseButton)`
  display: unset;
  margin: 10px 0;
`;
const LogoChangeBtn = styled(BaseButton)`
  display: unset;
  margin: 10px 50px;
`;
interface response {
  status?: boolean;
  message?: string;
  data?: [];
}
const YourSearchbutton = styled(BaseButton)`
  margin: 0;
  height: 57px;
  border: left; // Fix the typo here
  border-left: 0;
  border-radius: inherit;
`;
const SearchInputNew = styled(SearchInput)`
  .ant-input-search-button {
    display: none;
  }
  .ant-input-group-addon {
    display: none;
  }
`;
const WrapperBox = styled.div`
  display: flex;
`;

const ViewUserAvocado = (): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setloading] = useState<boolean>(false);
  const [show, setshow] = useState<boolean>(false);
  const [item, setitem] = useState<response[]>([]);
  const [email, setemail] = useState<string>('');
  const [sub, setSub] = useState([]);
  const user = useAppSelector((state: any) => state.user.user);
  const token = useAppSelector((state) => state.auth.token);
  const [open, setOpen] = useState(false);
  const permissions = JSON.parse(localStorage.getItem('permissions') as string);
  const adminPermission = permissions?.pagePermissions?.find(
    (e: { page_name: string }) => e?.page_name === 'Admin',
  )?.permissions;
  const handleDownloadClick = async () => {
    setloading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_EC_URL}/user/avocado/avocado-user?search=${email}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('res', response);
      setitem(response.data.data as []);
      notificationController.success({ message: 'Success Fetching Users' });
      setshow(true);
    } catch (error) {
      console.error('Error·downloading·users:', error);
    } finally {
      setloading(false);
    }
  };
  function handleModal() {
    setOpen(true);
  }
  return (
    <>
      {adminPermission?.load_user && (
        <YourSubscriptionbutton disabled={loading} onClick={handleDownloadClick}>
          Load Users
        </YourSubscriptionbutton>
      )}
      {adminPermission?.logo_change && <LogoChangeBtn onClick={() => handleModal()}>Logo change</LogoChangeBtn>}
      <PopupModal open={open} setIsModalOpen={setOpen} content={<LogoChange />} />
      <WrapperBox style={{ marginBottom: '10px' }}>
        <SearchInputNew
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e) => setemail(e.target.value as any)}
          placeholder="Enter Email"
          allowClear
        />
        <YourSearchbutton disabled={loading || email.trim() === ''} onClick={handleDownloadClick}>
          search
        </YourSearchbutton>
      </WrapperBox>
      {show ? <ActivityStory item={item as []} /> : ''}
    </>
  );
};

export default ViewUserAvocado;
