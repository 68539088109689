import React from 'react';
// import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
// import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
// import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
// import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {
  return (
    <BaseRow justify="space-between" align="middle">
      {/* <BaseCol>
        <ProfileDropdown />
      </BaseCol> */}

      <BaseCol>
        <BaseRow align="middle">
          {/* <BaseCol>
            <NotificationsDropdown />
          </BaseCol> */}

          {/* <BaseCol>
            <HeaderSearch />
          </BaseCol> */}
          {/* {user.role === 'ADMIN' ? null : (
            <BaseCol>
              <Helpbtn onClick={() => Navigate('dashboard/helpsupport')}>Help & Support</Helpbtn>
            </BaseCol>
          )} */}
          {/* <BaseCol>
            {' '}
            <Link to="dashboard/helpsupport" className="">
              Contact Us
            </Link>
          </BaseCol> */}

          {/* <BaseCol>
            <SettingsDropdown />
          </BaseCol> */}
          <BaseCol>
            <ProfileDropdown />
          </BaseCol>
        </BaseRow>
      </BaseCol>

      <S.BurgerCol>
        <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
      </S.BurgerCol>
    </BaseRow>
  );
};
