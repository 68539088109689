import styled from 'styled-components';
import { LAYOUT, media } from '@app/styles/themes/constants';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseDivider } from '@app/components/common/BaseDivider/BaseDivider';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
export const custdIV = styled(BaseCard)`
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  padding: 25px !important;
  margin: 15px;
  border-radius: 8px;
  .ant-card-body {
    padding: 0 !important;
  }
`;
export const HomeTable = styled(BaseTable)`
  .ant-table {
    background: white;
  }
`;
export const Head = styled.h1`
  font-size: 30px;
  text-align: left;
  font-weight: bold;
`;
export const Description = styled.h1`
  font-size: 20px;
  text-align: left;
  font-weight: 500;
`;
export const MainBoxContainer = styled.div`
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgb(0 0 0 / 20%), 0px 0px 1px rgba(0, 0, 0, 0.6);
`;
export const MainBox = styled.div`
  background-color: white;
  padding: 30px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgb(0 0 0 / 20%), 0px 0px 1px rgba(0, 0, 0, 0.6);
`;
export const BaseCardBox = styled(BaseCard)`
  background-color: #006c3c;
  border-radius: 12px;
  .ant-card-body {
    padding: 20px;
    color: white;
  }
  h2 {
    color: white;
  }
`;
export const Divider = styled(BaseDivider)`
  background-color: #00000029;
  margin: 0;
`;
export const SubHead = styled.h2`
  font-size: 25px;
  font-weight: bold;
`;
export const Content = styled.p`
  font-size: 14px;
  margin-bottom: 30px;
`;
export const MarkText = styled.span`
  color: #006c3c;
  font-weight: 700;
`;
export const GetStartedbutton = styled(BaseButton)`
  display: unset;
  margin: 10px 0;
  background-color: #72b114;
  color: white;
`;
export const MainRow = styled(BaseRow)`
  border-radius: 6.5px;
  align-items: stretch;
  justify-content: center;

  @media only screen and ${media.xl} {
    jusjustify-content: space-between;
  }
`;
export const RightSideCol = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--sider-background-color);
  overflow-y: auto;
`;

export const LeftSideCol = styled(BaseCol)`
  .ant-card {
    margin-right: 15px !important;
  }
  :nth-child(3) .ant-card {
    margin-right: 0px !important;
  }
  @media only screen and ${media.xl} {
    padding: 0;
    overflow: auto;
  }
  }
  @media (max-width: 1279px) {
    .ant-card {
      margin-right: 0 !important;
    }
  }
`;

export const Space = styled.div`
  margin: 1rem 0;
`;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;

  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;

export const BlockWrapper = styled.div`
  display: block;
  gap: 15px;
  overflow-y: auto;
  justify-content: space-between;
  align-item: center;
`;

export const Item = styled.div`
  display: flex;
`;

export const ItemFlex = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  font-size: 20px;
`;
export const ItemFlextext = styled.div`
  display: block;
`;
