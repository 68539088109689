/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { media } from '@app/styles/themes/constants';
import { notificationController } from '@app/controllers/notificationController';
import { getcountryList } from '@app/api/Maketfootage.api';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { countriesUpdate } from '@app/api/countriesUpdate.api';

const Title = styled.h2`
  color: #3c3c3c;
  fontweight: 700px;
  font-size: 30px;
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgb(0 0 0 / 20%), 0px 0px 1px rgba(0, 0, 0, 0.6);
  padding: 40px;
  @media only screen and ${media.md} {
    width: 50%;
  }
`;
const AddMarket: React.FC = () => {
  const [loading, setLoading] = useState<any>(false);
  const [countries, setCountries] = useState<any>();
  const fetchCountries = useCallback(async () => {
    try {
      const data: any = await getcountryList();
      setCountries(data.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  }, []);

  useEffect(() => {
    // Fetch countries
    fetchCountries();
  }, [fetchCountries]);
  const handleCheckboxChange = async (countryId: string, checked: boolean) => {
    try {
      setLoading(true);
      // Make API call to update country status
      await countriesUpdate(countryId, { status: checked });
      //   setCountries((prevCountries: any) => {
      //     return prevCountries.map((country: any) => {
      //       if (country._id === countryId) {
      //         return { ...country, status: checked };
      //       }
      //       return country;
      //     });
      //   });
      // If API call is successful, update UI or handle accordingly
      // For example, you can fetch updated data again
      notificationController.success({
        message: 'Country Status Updated',
      });
      setLoading(false);
      fetchCountries();
    } catch (error) {
      console.error('Error updating country status:', error);
      setLoading(false);
    }
  };
  return (
    <Container>
      <Title>Update Market</Title>
      {loading ? (
        <div style={{ textAlign: 'center', margin: '20px' }}>
          <BaseSpin />
        </div>
      ) : (
        <>
          {countries?.length === 0 ? (
            <div>Country Data Not Found</div>
          ) : (
            countries?.toReversed()?.map((country: any) => (
              <div key={country._id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginBottom: '7px' }}> {country.name}</div>
                <div style={{ marginBottom: '7px' }}>
                  <BaseCheckbox
                    checked={country.status}
                    // disabled={disabled}
                    onChange={(event) => handleCheckboxChange(country._id, event.target.checked)}
                  ></BaseCheckbox>
                </div>
              </div>
            ))
          )}
        </>
      )}
    </Container>
  );
};

export default AddMarket;
