import ViewUserAvocado from './AvocadoUser/ViewUsers';

const Admin: React.FC = () => {
  return (
    <>
      <ViewUserAvocado />
    </>
  );
};

export default Admin;
