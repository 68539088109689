/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
// import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
// import { ThemePicker } from '../components/settingsDropdown/settingsOverlay/ThemePicker/ThemePicker';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
// import { SiderLogo } from '@app/components/layouts/main/sider/SiderLogo';
// import { MdMenu } from 'react-icons/md';
import logo from 'assets/logo.png';
import logoDark from 'assets/logo-dark.png';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import axios from 'axios';
import { GetLogoChange } from '@app/api/LogoChange/LogoChange.api';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
  toggleSider: () => void;
  isCollapsed: boolean;
}
const Helpbtn = styled(BaseButton)`
  background: #72b114;
  color: white;
  margin-right: 10px;
`;

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout, toggleSider, isCollapsed }) => {
  const Navigate = useNavigate();
  const theme = useAppSelector((state) => state.theme.theme);
  const img = theme === 'dark' ? logoDark : logo;
  const user = useAppSelector((state: any) => state.user.user);
  const token = useAppSelector((state) => state.auth.token);
  // const [logoImg, setLogoImg] = useState<any>();
  const handlePermisson = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_EC_URL}/assignPagePermission/${user?.id}/2`);
      localStorage.setItem('permissions', JSON.stringify(response.data.data));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handlePermisson();
  }, []);

  const fetchImage = async () => {
    try {
      const response: any = await GetLogoChange();
      localStorage.setItem('logo', JSON.stringify(response.data[0]));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchImage();
  }, []);
  const storedLogo = localStorage.getItem('logo');
  const image: any = storedLogo ? JSON.parse(storedLogo) : '';
  useEffect(() => {
    const ulElement = document.querySelector('ul');
    if (!ulElement) return; // Null check

    const liElements = ulElement.querySelectorAll('li');
    const svgElements = ulElement.querySelectorAll('li svg');
    if (!isCollapsed) {
      console.log('isCollapsedisCollapsed check');
      ulElement.classList.add('collapsedbyclick');
      liElements.forEach((li) => li.classList.add('collapsedbyclick'));
      svgElements.forEach((svg) => svg.classList.add('collapsedbyclick'));
    } else {
      ulElement.classList.remove('collapsedbyclick');
      liElements.forEach((li) => li.classList.remove('collapsedbyclick'));
      svgElements.forEach((svg) => svg.classList.remove('collapsedbyclick'));
    }
  }, [isCollapsed]);
  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between">
        <BaseCol xl={15} xxl={12}>
          {/* <HeaderSearch /> */}
        </BaseCol>
        {/* <BaseCol>
          <S.GHButton />
        </BaseCol> */}
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseCol lg={6} xxl={8}>
        {/* <HeaderSearch /> */}

        <S.SiderLogoDiv>
          {/* <S.CollapseButton
            shape="circle"
            size="small"
            $isCollapsed={isCollapsed}
            icon={<MdMenu size={40} style={{ marginRight: '60px' }} onClick={toggleSider} />}
            onClick={toggleSider}
          /> */}

          <S.BurgerCol>
            <S.MobileBurgerNew onClick={toggleSider} isCross={!isCollapsed} />
          </S.BurgerCol>
          {/* <MdMenu size={40} style={{ marginRight: '60px' }} onClick={toggleSider} /> */}
          <S.SiderLogoLink to="/">
            <img
              src="https://avocado-stageing.s3.ap-southeast-2.amazonaws.com/1720001835648_Avocados%20Australia-logo-G%201.png"
              alt="Lightence"
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </S.SiderLogoLink>
        </S.SiderLogoDiv>
      </BaseCol>
      {/* <BaseCol>
        <S.GHButton />
      </BaseCol> */}
    </>
  );

  return (
    <BaseRow justify="space-between" align="middle" className="Dasktop_header">
      {leftSide}

      <S.ProfileColumn xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow align="middle" justify="end" gutter={[5, 5]}>
          <BaseCol>
            <BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]}>
              {/* <BaseCol>
                {user.role === 'ADMIN' ? null : (
                  <Helpbtn
                    onClick={() => Navigate('dashboard/helpsupport')}
                    icon={<IoIosHelpCircleOutline fontSize="25px" />}
                  >
                    Help & Support
                  </Helpbtn>
                )}
              </BaseCol> */}
              {/* <BaseCol>
                <ThemePicker />
              </BaseCol> */}
              {/* <BaseCol>
                <HeaderFullscreen />
              </BaseCol> */}

              {/* <BaseCol>
                <NotificationsDropdown />
              </BaseCol> */}

              {/* <BaseCol>
                <SettingsDropdown />
              </BaseCol> */}

              <BaseCol>
                <ProfileDropdown />
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
  );
};
