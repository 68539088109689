import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { sidebarDashboard, SidebarNavigationItem } from '../sidebarNavigation';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = sidebarDashboard.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const user = useAppSelector((state: any) => state.user.user);
  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];
  const permissionFromLocalStorage = localStorage.getItem('permissions');
  const userPermissions = permissionFromLocalStorage ? JSON.parse(permissionFromLocalStorage) : null;
  const filteredSidebar = sidebarDashboard.filter(
    (e) =>
      userPermissions?.pagePermissions?.find((event) => e?.key === 'admin' || event?.key === e?.key)?.permissions
        ?.show_page,
  );
  const adminSideBar = sidebarDashboard.filter(
    (e) => userPermissions?.pagePermissions?.find((event) => event?.key === e?.key)?.permissions?.show_page,
  );
  const roleWiseDashBoard = user?.role === 'CLIENT' ? filteredSidebar : adminSideBar;
  const sidebarDashboardFilter = roleWiseDashBoard.filter(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (nav: any) =>
      !(
        (user?.role === 'CLIENT' ||
          !(
            user?.role === 'ADMIN' &&
            (location.pathname === '/dashboard/profile/profilesetting' || location.pathname === '/dashboard/add-market')
          )) &&
        nav.key === 'updatemarket'
      ),
  );
  const filterForAdminSidebarDashboard = sidebarDashboardFilter.filter(
    (nav) => !(user?.role === 'CLIENT' && nav.key === 'admin'),
  );
  const openedSubmenu = filteredSidebar.find(({ children }) => children?.some(({ url }) => url === location.pathname));
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  useEffect(() => {
    setLoading(true);

    const asyncOperation = setTimeout(() => {
      setLoading(false);
    }, 100);

    return () => clearTimeout(asyncOperation);
  }, [location.pathname]);

  return (
    <>
      {' '}
      {loading ? (
        ''
      ) : (
        <S.Menu
          mode="inline"
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={defaultOpenKeys}
          onClick={() => setCollapsed(true)}
          items={filterForAdminSidebarDashboard.map((nav) => {
            const isSubMenu = nav.children?.length;

            return {
              key: nav.key,
              title: t(nav.title),
              label: isSubMenu ? t(nav.title) : <Link to={nav.url || ''}>{t(nav.title)}</Link>,
              icon: nav.icon,
              children:
                isSubMenu &&
                nav.children &&
                nav.children.map((childNav) => ({
                  key: childNav.key,
                  label: <Link to={childNav.url || ''}>{t(childNav.title)}</Link>,
                  title: t(childNav.title),
                })),
            };
          })}
        />
      )}
    </>
  );
};

export default SiderMenu;
