/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { InboxOutlined } from '@ant-design/icons';
import { notificationController } from '@app/controllers/notificationController';

import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { useState } from 'react';
import { ImportMarketreport } from '@app/api/ImportMarketreport.api';

interface DataLog {
  fetch: any;
  setIsModalOpen: any;
}
const MediaLibrarytImportModel: React.FC<DataLog> = ({ fetch, setIsModalOpen }) => {
  const [loading, setloading] = useState<any>(false);

  const Container = styled.div``;
  const Title = styled.h2`
    color: #3c3c3c;
    fontweight: 700px;
    font-size: 25px;
  `;
  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const { t } = useTranslation();
  // const [isLoading, setLoading] = useState(false);

  // const handleSubmit = (values: LoginFormData) => {
  //   console.log('values', values);
  //   // setLoading(true);
  //   // dispatch(doLogin(values))
  //   //   .unwrap()
  //   //   .then(() => navigate('/'))
  //   //   .catch((err) => {
  //   //     notificationController.error({ message: err.message });
  //   //     setLoading(false);
  //   //   });
  // };

  const handleSubmit = async (values: any) => {
    const formData: any = new FormData();
    formData.append('images', values.image.file.originFileObj); // Assuming 'upload' is the field for PDF
    setloading(true);
    try {
      if (validateFile(values.image.file) === true) {
        await ImportMarketreport(formData);
        fetch();
        // Display success notification
        notificationController.success({
          message: 'Data Submitted Successfully',
        });
        setloading(false);
        setIsModalOpen(false);
      }
      // Use addReport API function
    } catch (error) {
      // Handle error
      console.error('Error adding report:', error);
      notificationController.error({
        message: 'Failed to submit data',
      });
    }
  };
  const FormWrapper = styled(Auth.FormWrapper)`
    padding: initial !important;
    width: initial !important;
    overflow: auto;
    background-color: initial !important;
    border-radius: none;

    @media only screen and ${media.xs} {
      padding: 2.5rem 1.25rem;
      width: 20.75rem;
      max-height: calc(100vh - 3rem);
    }

    @media only screen and ${media.md} {
      padding: 2.5rem;
      width: 31.75rem;
      max-height: calc(100vh - 3rem);
    }
  `;

  const DraggerIconWrapper = styled.div`
    font-size: 4rem;
    color: var(--primary-color);
  `;
  const DraggerTitle = styled.div`
    font-size: ${FONT_SIZE.xl};
    font-weight: ${FONT_WEIGHT.bold};
  `;
  const DraggerDescription = styled.div`
    font-size: ${FONT_SIZE.md};
    padding: 0 1rem;
  `;
  const SubmitButton = styled(Auth.SubmitButton)`
    background: #72b114;
    color: white;
  `;
  const validateFile = (file: File) => {
    // Validate file type
    const isImageOrVideo = file.type.startsWith('image/') || file.type.startsWith('video/');
    if (!isImageOrVideo) {
      setloading(false);
      notificationController.error({ message: 'Invalid file type. Please upload a Image and Viedo file.' });
      return false;
    }

    // Validate file size (2 MB limit)
    const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
    if (file.size > maxSize) {
      setloading(false);
      notificationController.error({ message: 'File size exceeds the limit. Please upload a file up to 2 MB.' });
      return false;
    }
    setloading(true);
    return true;
  };
  const uploadProps = {
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (info: any) => {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        notificationController.success({ message: t('uploads.successUpload', { name: info.file.name }) });
      } else if (status === 'error') {
        // notificationController.error({ message: t('uploads.failedUpload', { name: info.file.name }) });
      }
    },
  };

  return (
    <Container>
      <Title>Import Images and Viedos Files </Title>
      {loading ? (
        <div style={{ textAlign: 'center', margin: '20px' }}>
          <BaseSpin />
        </div>
      ) : (
        <FormWrapper>
          <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional">
            <Auth.FormItem name="image" label="Upload" rules={[{ required: true, message: t('common.requiredField') }]}>
              <BaseUpload.Dragger {...uploadProps}>
                <DraggerIconWrapper>
                  <InboxOutlined />
                </DraggerIconWrapper>
                <DraggerTitle>{t('uploads.dragUpload')}</DraggerTitle>
                <DraggerDescription>{t('uploads.bulkUpload')}</DraggerDescription>
              </BaseUpload.Dragger>
            </Auth.FormItem>
            <BaseForm.Item noStyle>
              <SubmitButton type="primary" htmlType="submit">
                Submit
              </SubmitButton>
            </BaseForm.Item>
          </BaseForm>
        </FormWrapper>
      )}
    </Container>
  );
};

export default MediaLibrarytImportModel;
