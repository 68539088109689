/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { LoginOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { useAppSelector } from '@app/hooks/reduxHooks';
export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();
  const user = useAppSelector((state: any) => state.user.user);
  return (
    <div {...props}>
      <S.Text>
        <UserOutlined style={{ marginRight: '8px' }} />{' '}
        <Link to="dashboard/profile/personal-info">{t('profile.title')}</Link>
      </S.Text>
      <S.ItemsDivider />

      {user.role === 'ADMIN' && (
        <S.Text>
          <SettingOutlined style={{ marginRight: '8px' }} /> <Link to="dashboard/profile/profilesetting">Settings</Link>
        </S.Text>
      )}
      <S.Text>
        <LoginOutlined style={{ marginRight: '8px' }} /> <Link to="/logout">{t('header.logout')}</Link>
      </S.Text>
      {/* <BaseButton onClick={() => navigate('dashboard/profile/profilesetting')}>Setting</BaseButton> */}
      <S.ItemsDivider />
      {/* <S.Text>
        <Link to="/logout">{t('header.logout')}</Link>
      </S.Text> */}
    </div>
  );
};
