/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
// import { useAppDispatch } from '@app/hooks/reduxHooks';
// import { doLogin } from '@app/store/slices/authSlice';
// import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { InboxOutlined } from '@ant-design/icons';
import { notificationController } from '@app/controllers/notificationController';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { Form } from 'antd';
import { dashboardsettingUpdate, getdashboardsetting } from '@app/api/dashboardsetting.api';
// import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
// interface LoginFormData {
//   market_id: any;
//   retailer_id: any;
//   city: any;
//   state: any;
//   upload: any;
//   values: any;
//   email: string;
//   password: string;
// }
const Title = styled.h2`
  color: #3c3c3c;
  fontweight: 700px;
  font-size: 30px;
`;
const FormWrapper = styled(Auth.FormWrapper)`
  padding: initial !important;
  width: initial !important;
  overflow: auto;
  background-color: initial !important;
  border-radius: none;
  max-height: 100% !important;

  @media only screen and ${media.xs} {
    padding: 2.5rem 1.25rem;
    width: 20.75rem;
  }

  @media only screen and ${media.md} {
    padding: 2.5rem;
    width: 31.75rem;
  }
`;

const DraggerIconWrapper = styled.div`
  font-size: 4rem;
  color: var(--primary-color);
`;
const DraggerTitle = styled.div`
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.bold};
`;
const DraggerDescription = styled.div`
  font-size: ${FONT_SIZE.md};
  padding: 0 1rem;
`;
const SubmitButton = styled(Auth.SubmitButton)`
  background: #72b114;
  color: white;
`;
const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgb(0 0 0 / 20%), 0px 0px 1px rgba(0, 0, 0, 0.6);
  padding: 40px;
  @media only screen and ${media.md} {
    width: 50%;
  }
`;
const ProfileSetting: React.FC = () => {
  const [loading, setloading] = useState<any>(false);
  const [form] = Form.useForm();

  const [data, setdata] = useState<any>();
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const initValues: any = {
    title: data?.data[0]?.title || '',
    description: data?.data[0]?.description || '',
  };

  useEffect(() => {
    if (data) {
      // Update initValues when data changes
      const initValues = {
        title: data?.data[0]?.title || '',
        description: data?.data[0]?.description || '',
      };
      form.setFieldsValue(initValues);
    }
  }, [data, form]);
  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const { t } = useTranslation();
  // const [isLoading, setLoading] = useState(false);

  // const handleSubmit = (values: LoginFormData) => {
  //   console.log('values', values);
  //   // setLoading(true);
  //   // dispatch(doLogin(values))
  //   //   .unwrap()
  //   //   .then(() => navigate('/'))
  //   //   .catch((err) => {
  //   //     notificationController.error({ message: err.message });
  //   //     setLoading(false);
  //   //   });
  // };
  const dasboarddetail = async () => {
    setloading(true);
    try {
      const res = await getdashboardsetting();
      setdata(res);
      setloading(false);
    } catch (err) {
      console.log('err', err);
      setloading(false);
    }
  };

  useEffect(() => {
    dasboarddetail();
  }, []);
  const handleSubmit = async (values: any) => {
    const formData: any = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description); // Assuming 'text' is the name field in your form
    formData.append('image', values?.image?.file?.originFileObj); // Assuming 'upload' is the field for PDF
    setloading(true);
    try {
      if (validateFile(values?.image?.file) === true) {
        // Use addReport API function
        const res = await dashboardsettingUpdate(data?.data[0]?._id || '', formData);
        // Display success notification
        setdata(res);
        notificationController.success({
          message: 'Profile Settings Updated',
        });
        dasboarddetail();
      }
      //   form.resetFields();
      //   navigate('/dashboard/profile/profilesetting');
    } catch (error) {
      // Handle error
      console.error('Error adding report:', error);
      notificationController.error({
        message: 'Failed to submit data',
      });
      setloading(false);
      form.resetFields();
    }
  };
  const validateFile = (file: File) => {
    // Validate file type
    const isImage = file?.type?.startsWith('image/') || file === undefined;
    if (!isImage) {
      setloading(false);
      notificationController.error({ message: 'Invalid file type. Please upload a Image file.' });
      return false;
    }

    // Validate file size (200 MB limit)
    // const maxSize = 200 * 1024 * 1024; // 2 MB in bytes
    // if (file?.size > maxSize || file === undefined) {
    //   setloading(false);
    //   notificationController.error({ message: 'File size exceeds the limit. Please upload a file up to 200 MB.' });
    //   return false;
    // }
    setloading(true);
    return true;
  };
  const uploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
    },
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (info: any) => {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        notificationController.success({ message: t('uploads.successUpload', { name: info.file.name }) });
      } else if (status === 'error') {
        // notificationController.error({ message: t('uploads.failedUpload', { name: info.file.name }) });
      }
    },
  };

  return (
    <Container>
      <Title>Profile Settings</Title>
      {loading ? (
        <div style={{ textAlign: 'center', margin: '20px' }}>
          <BaseSpin />
        </div>
      ) : (
        <FormWrapper>
          <BaseForm
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={data && initValues}
          >
            <Auth.FormItem
              name="title"
              label="Title"
              rules={[
                { required: true, message: t('common.requiredField') },
                {
                  max: 550,
                  type: 'string',
                  message: t('The maximum character limit is 150 characters.'),
                },
              ]}
            >
              <Auth.FormInput />
            </Auth.FormItem>
            <Auth.FormItem
              name="description"
              label="Description"
              rules={[
                { required: true, message: t('common.requiredField') },
                {
                  max: 1450,
                  type: 'string',
                  message: t('The maximum character limit is 450 characters.'),
                },
              ]}
            >
              <Auth.FormInput.TextArea rows={5} placeholder="Please enter description" />
            </Auth.FormItem>

            <Auth.FormItem
              name="image"
              label="Upload Image"
              // rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <BaseUpload.Dragger {...uploadProps} maxCount={1}>
                <DraggerIconWrapper>
                  <InboxOutlined />
                </DraggerIconWrapper>
                <DraggerTitle>{t('uploads.dragUpload')}</DraggerTitle>
                <DraggerDescription>{t('uploads.bulkUpload')}</DraggerDescription>
              </BaseUpload.Dragger>
            </Auth.FormItem>

            <BaseForm.Item noStyle>
              <SubmitButton type="primary" htmlType="submit">
                Submit
              </SubmitButton>
            </BaseForm.Item>
          </BaseForm>
        </FormWrapper>
      )}
    </Container>
  );
};

export default ProfileSetting;
