/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './MarketCard.styles';
import { message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import moment from 'moment';

interface NftCardProps {
  nftItem: any;
  fetch: () => void;
}

export const MarketCard: React.FC<NftCardProps> = ({ nftItem }) => {
  const { isTablet } = useResponsive();
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    message.success('Text copied to clipboard');
  };
  const tabletLayout = (
    <>
      {/* <S.InfoHeader>
        <S.InfoText>@{nftItem.author}</S.InfoText>
      </S.InfoHeader> */}

      <S.InfoFooter>
        <S.CurrentBidWrapper>
          <S.BidCrypto> {nftItem.image}</S.BidCrypto>
          <CopyToClipboard text={nftItem.image} onCopy={handleCopy}>
            <S.CopyButton>{isCopied ? 'Copied!' : 'Copy to Clipboard'}</S.CopyButton>
          </CopyToClipboard>
          <S.CurrentBid>{nftItem.retailer_name}</S.CurrentBid>
        </S.CurrentBidWrapper>
      </S.InfoFooter>
    </>
  );

  const mobileLayout = (
    <>
      {/* <S.InfoRow>
        <S.InfoText>@{nftItem.author}</S.InfoText>
        <S.BidCrypto>
          {getCurrencyPrice(formatNumberWithCommas(nftItem.currentBidCrypto), CurrencyTypeEnum.ETH, false)}
        </S.BidCrypto>
      </S.InfoRow>

      <S.InfoRow>
        <S.CurrentBid>Current Bid</S.CurrentBid>
        <S.Bid>{getCurrencyPrice(formatNumberWithCommas(nftItem.currentBid), CurrencyTypeEnum.USD)}</S.Bid>
      </S.InfoRow> */}
      <S.InfoFooter>
        <S.CurrentBidWrapper>
          <S.BidCrypto> {nftItem.image}</S.BidCrypto>
          <CopyToClipboard text={nftItem.image} onCopy={handleCopy}>
            <S.CopyButton>{isCopied ? 'Copied!' : 'Copy to Clipboard'}</S.CopyButton>
          </CopyToClipboard>
          <S.CurrentBid>{nftItem.retailer_name}</S.CurrentBid>
        </S.CurrentBidWrapper>
      </S.InfoFooter>
    </>
  );

  return (
    <S.Card padding={0} $img={nftItem.image}>
      {nftItem.image.endsWith('.mp4') ? (
        <video width="100%" autoPlay controls>
          <source src={nftItem.image} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <S.NftImage src={nftItem.image} alt="nftImage" />
      )}
      <S.NftInfo>
        <S.InfoRow></S.InfoRow>
        {isTablet ? tabletLayout : mobileLayout}
        <S.Title>{moment(nftItem.createdAt).format('DD MMMM YYYY')}</S.Title>
      </S.NftInfo>
    </S.Card>
  );
};
