import React from 'react';
import { Modal } from 'antd';

interface ModalProps {
  open: boolean;
  title: string;
  content: React.ReactNode;
  onOk: () => void;
  onCancel: () => void;
}

const CustomModal: React.FC<ModalProps> = ({ title, open, onOk, onCancel, content }) => {
  return (
    <>
      <Modal title={title} open={open} onOk={onOk} onCancel={onCancel} footer={null}>
        {content}
      </Modal>
    </>
  );
};

export default CustomModal;
