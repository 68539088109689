import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { setUser } from '@app/store/slices/userSlice';
import { persistToken } from '@app/services/localStorage.service';
export const CustomSpin = styled(BaseSpin)`
  color: white;
  span {
    height: 50px;
    width: 50px;
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
    .ant-spin-dot-item {
      background-color: white !important;
    }
  }
`;

const EcPortalLogin: React.FC = () => {
  const { id, email, password } = useParams();
  let decodedPassword: string;
  if (password) {
    decodedPassword = decodeURIComponent(password);
  } else {
    // Handle the case where password is undefined
    // You can provide a default value or throw an error
    // For example:
    throw new Error('Password parameter is missing in the URL');
  }
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    const handleSubmit = async (values: any) => {
      setLoading(true);
      try {
        // Make API call with decoded password
        const res: any = await fetch(`${process.env.REACT_APP_EC_URL}/user/${id}/newUserLogin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });
        const data = await res.json();
        console.log('data', data?.data?.token);
        dispatch(setUser(data?.data?.user));
        persistToken(data?.data?.token);
        if (!res.ok) {
          throw new Error('Failed to login');
        }
        // Redirect to dashboard if login is successful
        navigate('/dashboard/home');
      } catch (error: any) {
        notificationController.error({ message: error.message });
      } finally {
        setLoading(false);
      }
    };

    if (id && email && password) {
      const decodedPassword = decodeURIComponent(password);
      handleSubmit({ email: email, password: decodedPassword });
    }
  }, []); // Empty dependency array to run the effect only once
  return (
    <>
      <h1 style={{ fontSize: '3rem', fontWeight: 'bolder', color: 'white' }}>logging in..................</h1>
      {isLoading && <CustomSpin />}
    </>
  );
};

export default EcPortalLogin;
