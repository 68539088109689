/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { FONT_FAMILY, FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import styled from 'styled-components';

// export const Title = styled(BaseTypography.Title)`
//   &.ant-typography {
//     margin-bottom: 0;

//     font-weight: ${FONT_WEIGHT.semibold};

//     font-size: ${FONT_SIZE.xs};
//   }
// `;

export const ActivityRow = styled(BaseRow)``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: auto;
`;
export const Title = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.xs};

  font-family: ${FONT_FAMILY.secondary};
`;

export const Status = styled(BaseTypography.Text)<any>`
  color: ${(props) => `var(--${props.$color}-color)`};

  font-size: ${FONT_SIZE.xs};

  font-family: ${FONT_FAMILY.secondary};
`;

export const DateText = styled(Title)`
  font-weight: ${FONT_WEIGHT.regular};
`;

export const Text = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.xs};

  font-weight: ${FONT_WEIGHT.regular};

  font-family: ${FONT_FAMILY.secondary};
`;
