import { css } from 'styled-components';

export const resetCss = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 1rem;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 1.25rem;
    border: 0.375rem solid transparent;
    background-clip: content-box;
  }

  body {
    font-weight: 500;
  }

  img {
    display: block;
  }

  .newCustom {
    padding: 3px;
    position: fixed;
    left: 0;
    bottom: 1%;
    border-top: 1px solid white;
    color: white;
    z-index: 999;
    display: block;
  }
  .newCustom2 {
    padding: 3px;
    position: fixed;
    left: 0;
    bottom: 1%;
    border-top: 1px solid white;
    color: white;
    font-size: 30px;
    z-index: 999;
    width: 20%;
    display: block;
  }
  .footer_logo {
    padding: 15px;
    position: fixed;
    left: 0;
    bottom: 10%;
    z-index: 999;
    display: block;
  }

  @media (max-width: 540px) {
    .ant-layout-sider-zero-width + .footer_logo {
      display: none;
    }
    .ant-layout-sider-zero-width + .newCustom {
      display: none;
    }
    .ant-layout-sider-zero-width + .newCustom2 {
      display: none;
    }
    .footer_logo {
      left: inherit;
      right: 7%;
    }
    .newCustom {
      display: none;
    }
    .newCustom2 {
      left: inherit;
      right: 0%;
      width: auto;
      padding: 5px 45px;
    }
  }

  .ant-carousel {
    .slick-next {
      &::before {
        content: '';
      }
    }
    .slick-prev {
      &::before {
        content: '';
      }
    }
  }
`;
