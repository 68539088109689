import React, { useEffect, useRef } from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title } = Typography;

const TermsOfUse: React.FC = () => {
  const layoutRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const scrollToTop = () => {
      if (layoutRef.current) {
        layoutRef.current.scrollIntoView({
          behavior: 'smooth',
        });
        window.scrollBy(0, -450);
      }
    };
    console.log('tarn for use');
    // Scroll to top when component mounts
    scrollToTop();
  }, [layoutRef]);
  return (
    <Layout style={{ padding: '24px', background: '#fff' }}>
      <div ref={layoutRef}>
        <Content style={{ maxWidth: '1000px', margin: '0 auto' }}>
          <Title level={2}>Terms of Use</Title>
          <Title level={4}>Version: 1 [November 2023]</Title>
          <div>
            <div>
              <p style={{ margin: '6pt 5.55pt 20px 5.9pt', textAlign: 'justify', lineHeight: '108%' }}>
                <span style={{ lineHeight: '108%' }}>
                  The use of the “Avocados Australia Export Intel Portal” software-as-a-service Portal, and the
                  software, information, intellectual property and services comprising that Portal, accessible at{' '}
                </span>
                <a href="https://avocado.exportconnectportal.com" style={{ textDecoration: 'none' }}>
                  <u>
                    <span style={{ lineHeight: '108%', color: '#1155cc' }}>
                      https://avocado.exportconnectportal.com
                    </span>
                  </u>
                </a>
                <span style={{ lineHeight: '108%' }}> (</span>
                <strong>
                  <span style={{ lineHeight: '108%' }}>Portal</span>
                </strong>
                <span style={{ lineHeight: '108%' }}>
                  ), which is owned or licensed by Export Connect Portal Pty Ltd is subject at all times to these
                  Avocados Australia Portal Terms of Use (this{' '}
                </span>
                <strong>
                  <span style={{ lineHeight: '108%' }}>Agreement</span>
                </strong>
                <span style={{ lineHeight: '108%' }}>
                  ). By clicking “Subscribe” or “I accept the Terms of Use” (or similar wording), by signing a document
                  in which you expressly agree to be bound by this Agreement or by using any similar mechanism
                  indicating your acceptance of this Agreement, or otherwise by using the Portal, you (the{' '}
                </span>
                <strong>
                  <span style={{ lineHeight: '108%' }}>User</span>
                </strong>
                <span style={{ lineHeight: '108%' }}>
                  ) will be deemed to have accepted and will be bound by the terms and conditions of this Agreement
                  (including as amended from time to time).
                </span>
              </p>
              <p style={{ margin: '6pt 5.55pt 20px 5.9pt', textAlign: 'justify', lineHeight: '108%' }}>
                <strong>
                  <span>You must be over 18 years of age to use the Portal.</span>
                </strong>
                <span>
                  {' '}
                  By entering into this Agreement, you warrant and represent to Avocados Australia that you are over 18
                  years of age.{' '}
                </span>
              </p>
              <h1
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>1.</span>
                <span style={{ width: '14.94pt', display: 'inline-block' }}> </span>
                <span>PROVISION OF THE PORTAL</span>
              </h1>
              <p
                style={{
                  margin: '6pt 5.55pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>1.1</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Avocados Australia provides Users with access to the Portal and the Services pursuant to the terms of
                  the Licence (as defined in{' '}
                </span>
                <strong>
                  <span>clause 2.1</span>
                </strong>
                <span>
                  ) and this Agreement. The purpose of the Portal is to allow Users to use their computer, mobile phone,
                  tablet, or other device (subject to functionality permitted by the Portal) to obtain and utilize data
                  supplied by Avocados Australia and Avocados Australia (
                </span>
                <strong>
                  <span>Portal Data</span>
                </strong>
                <span>
                  ) (as authorised under an applicable Services Agreement between a Primary User and Avocados
                  Australia).{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.55pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>1.2</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>A company or organization (</span>
                <strong>
                  <span>Primary User</span>
                </strong>
                <span>
                  ) that enters into a commercial agreement with Avocados Australia for paid access to the Portal and
                  the Services (
                </span>
                <strong>
                  <span>Services Agreement</span>
                </strong>
                <span>
                  ) may authorise its representatives, agents, officers, directors, employees, and contractors to access
                  and use the Portal (
                </span>
                <strong>
                  <span>Authorised Users</span>
                </strong>
                <span>
                  ), subject to any such Authorised Users creating an appropriate account for use of the Portal, being
                  approved as Authorised Users by Avocados Australia, and agreeing to be bound by this Agreement as
                  applicable to them.
                </span>
                <span> </span>
                <span>
                  All use of the Portal by Primary Users (including the authorization of Authorised Users) will be
                  governed by both:
                </span>
              </p>
              <ol type="a" className="awlist1" style={{ margin: '0pt', paddingLeft: '0pt' }}>
                <li
                  style={{
                    margin: '6pt 5.55pt 6pt 42.55pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '3.2pt', display: 'inline-block' }}> </span>the terms of this Agreement; and
                </li>
                <li
                  style={{
                    margin: '6pt 5.55pt 6pt 42.55pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '3.2pt', display: 'inline-block' }}> </span>the terms of the Services Agreement
                  agreed between the Primary User and Avocados Australia.
                </li>
              </ol>
              <p style={{ margin: '6pt 5.55pt 6pt 28.35pt', textAlign: 'justify', lineHeight: '108%' }}>
                <span>(together, </span>
                <strong>
                  <span>Primary User Agreement</span>
                </strong>
                <span>). </span>
              </p>
              <h1
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>2.</span>
                <span style={{ width: '14.94pt', display: 'inline-block' }}> </span>
                <span>LICENCE </span>
              </h1>
              <p
                style={{
                  margin: '6pt 5.55pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>2.1</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Subject to payment of the Charges set out in an applicable Services Agreement and compliance with this
                  Agreement, Avocados Australia grants to the User a non-exclusive, non-transferable, revocable licence
                  (
                </span>
                <strong>
                  <span>Licence</span>
                </strong>
                <span>) to:</span>
              </p>
              <p
                style={{
                  margin: '6pt 5.55pt 6pt 42.55pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(a)</span>
                <span style={{ width: '3.2pt', display: 'inline-block' }}> </span>
                <span>
                  use the Portal within the term or duration of the Licence as set out in an applicable Services
                  Agreement (
                </span>
                <strong>
                  <span>Licence Period</span>
                </strong>
                <span>
                  ) agreed between Avocados Australia and the Primary User (as defined in the applicable Services
                  Agreement);{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.55pt 6pt 42.55pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(b)</span>
                <span style={{ width: '3.2pt', display: 'inline-block' }}> </span>
                <span>as the Primary User, sublicence the use of the Portal to Authorised Users.</span>
              </p>
              <p
                style={{
                  margin: '6pt 5.55pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>2.2</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>Notwithstanding </span>
                <strong>
                  <span>clause 2.1</span>
                </strong>
                <span>
                  , Avocados Australia may refuse to grant a Licence to any person, for any reason whatsoever in its
                  sole discretion, or may grant a Licence only subject to additional terms and/or conditions.{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.55pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>2.3</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  A Primary User may only sublicense or authorise its Authorised Users to use the Portal on the
                  condition that they also agree to be personally bound by this Agreement. An Authorised User may not
                  otherwise sublicense, deliver, transfer or assign the Licence to any other person except with the
                  written permission of the Primary User. The Primary User and Authorised User will be jointly and
                  severally liable for any breach by the Authorised User of this Agreement.{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.7pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>2.4</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>The Licence Period may, subject to </span>
                <strong>
                  <span>clause 4</span>
                </strong>
                <span>, at Avocados Australia’s discretion be:</span>
              </p>
              <p
                style={{
                  margin: '6pt 5.55pt 6pt 42.55pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(a)</span>
                <span style={{ width: '3.2pt', display: 'inline-block' }}> </span>
                <span>
                  an ongoing or continuously renewing period of 30 days, in which fees are applicable only in respect of
                  each 30-day period (
                </span>
                <strong>
                  <span>Ongoing Licence</span>
                </strong>
                <span>);</span>
              </p>
              <p
                style={{
                  margin: '6pt 5.55pt 6pt 42.55pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(b)</span>
                <span style={{ width: '3.2pt', display: 'inline-block' }}> </span>
                <span>limited to a specific period of time, which may or may not be capable of renewal (</span>
                <strong>
                  <span>Subscription Licence</span>
                </strong>
                <span>); or </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.55pt 6pt 42.55pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(c)</span>
                <span style={{ width: '3.71pt', display: 'inline-block' }}> </span>
                <span>
                  limited to an evaluation period, and/or limited in functionality during that period, which is not
                  capable of being renewed on the same terms (
                </span>
                <strong>
                  <span>Evaluation Licence</span>
                </strong>
                <span>).</span>
              </p>
              <p
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>2.5</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Unless otherwise specified by Avocados Australia, or agreed between the parties, the Licence granted
                  will by default be an Evaluation Licence. The Evaluation Licence may be converted to an Ongoing
                  Licence or a Subscription Licence, as further described in{' '}
                </span>
                <strong>
                  <span>clause 3</span>
                </strong>
                <span>. </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.65pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span style={{ fontFamily: 'Arial' }}>2.6</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span style={{ lineHeight: '108%' }}>
                  If the Portal is provided on an Ongoing Licence or Subscription Licence basis, the Licence will
                  continue for such time as the Primary User continues to pay all Charges to Avocados Australia (or
                  until the set period for which fees have been paid expires), unless terminated earlier pursuant to{' '}
                </span>
                <strong>
                  <span style={{ lineHeight: '108%' }}>clause </span>
                </strong>
                <a href="#_heading_h.1t3h5sf" style={{ textDecoration: 'none' }}>
                  <strong>
                    <span style={{ lineHeight: '108%', color: '#000000' }}>4</span>
                  </strong>
                </a>
                <a href="#_heading_h.1t3h5sf" style={{ textDecoration: 'none' }}>
                  <span style={{ lineHeight: '108%', color: '#000000' }}>.</span>
                </a>
                <span style={{ lineHeight: '108%' }}> If the Portal is provided on an Evaluation Licence basis, </span>
                <strong>
                  <span style={{ lineHeight: '108%' }}>clause 3</span>
                </strong>
                <span style={{ lineHeight: '108%' }}> will apply.</span>
              </p>
              <p
                style={{
                  margin: '6pt 5.65pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>2.7</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Avocados Australia may restrict the functionality of the Portal, or the User’s access to parts of the
                  Portal based on the subscription level, licence type, and fees payable under any Subscription Licence
                  or Ongoing Licence.
                </span>
                <span> </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.65pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>2.8</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  The Primary User must pay the Charges in the amount and manner agreed between the parties in respect
                  of the Licence as set out in the Services Agreement or as otherwise agreed between the parties in
                  writing. The Primary User must provide Avocados Australia, where applicable, with all necessary
                  information to enable Avocados Australia to take such payment and ensure that such information is
                  current and correct at all times.
                </span>
                <span> </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.65pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>2.9</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Avocados Australia may change the fees payable in respect of use of the Portal at any time on notice
                  to a User, and those fees will apply to any subsequent transaction (for Ongoing Licences) or Licence
                  Period (for Subscription Licences). If the User does not accept the change in fees, that User must
                  cease using the Portal before those fees become effective.
                </span>
                <span> </span>
              </p>
              <h1
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>3.</span>
                <span style={{ width: '14.94pt', display: 'inline-block' }}> </span>
                <span>EVALUATION LICENCE</span>
              </h1>
              <p
                style={{
                  margin: '6pt 6.1pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>3.1</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  If the Portal is provided on an Evaluation Licence basis, this Licence commences on the date that the
                  Evaluation Licence is granted (or such other date as agreed between the parties). The Evaluation
                  Licence will either expire at the end of the evaluation period as specified by Avocados Australia, or
                  if no such period is specified, then expire upon Avocados Australia disabling the Portal’s
                  functionality or the User’s access to the Portal (
                </span>
                <strong>
                  <span>Evaluation Term</span>
                </strong>
                <span>).</span>
              </p>
              <p
                style={{
                  margin: '6pt 6.05pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>3.2</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Avocados Australia may at its discretion, limit the functionality of the Portal and/or restrict access
                  to certain functionality of the Portal during the Evaluation Term.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.55pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>3.3</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  The Primary User may purchase a Subscription Licence for the Portal and unlock its full functionality
                  at any time by contacting Avocados Australia via the purchase functionality contained in the Portal
                  (if applicable). Upon expiry of the Evaluation Term, Avocados Australia may offer the User the option
                  to purchase a Subscription Licence of the Portal, subject to payment of all applicable fees specified
                  by Avocados Australia.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.55pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>3.4</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  The User may at any time also purchase an Ongoing Licence, by payment of transaction specific fees.
                </span>
                <span> </span>
                <span>
                  That Ongoing Licence will, on its termination, revert back to an Evaluation Licence. Subsequent
                  Ongoing Licences may then be purchased by the User in accordance with this clause and{' '}
                </span>
                <strong>
                  <span>clause 2.4(a)</span>
                </strong>
                <span>.</span>
                <span> </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.55pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span style={{ fontFamily: 'Arial' }}>3.5</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span style={{ lineHeight: '108%' }}>
                  A Licence granted on an Evaluation Licence basis terminates automatically without further notice from
                  Avocados Australia upon the expiration of the Evaluation Term, unless the User elects to purchase a
                  Subscription Licence of the Portal pursuant to{' '}
                </span>
                <strong>
                  <span style={{ lineHeight: '108%' }}>clause </span>
                </strong>
                <a href="#_heading_h.2et92p0" style={{ textDecoration: 'none' }}>
                  <strong>
                    <span style={{ lineHeight: '108%', color: '#000000' }}>3.3</span>
                  </strong>
                </a>
                <a href="#_heading_h.2et92p0" style={{ textDecoration: 'none' }}>
                  <span style={{ lineHeight: '108%', color: '#000000' }}>.</span>
                </a>
                <span style={{ lineHeight: '108%' }}>
                  {' '}
                  Upon expiry or termination of the Evaluation Licence the User must, without limitation, comply with
                  its obligations under{' '}
                </span>
                <strong>
                  <span style={{ lineHeight: '108%' }}>clause </span>
                </strong>
                <a href="#_heading_h.2s8eyo1" style={{ textDecoration: 'none' }}>
                  <strong>
                    <span style={{ lineHeight: '108%', color: '#000000' }}>5.4</span>
                  </strong>
                </a>
                <a href="#_heading_h.2s8eyo1" style={{ textDecoration: 'none' }}>
                  <span style={{ lineHeight: '108%', color: '#000000' }}>.</span>
                </a>
              </p>
              <p
                style={{
                  margin: '6pt 5.65pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>3.6</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Subject to payment of applicable Charges, Avocados Australia will supply Authorised Users with access
                  to the Portal and, via available Portal functionality, the Portal Data, and any other services
                  identified as Services and Additional Services in an applicable Services Agreement (
                </span>
                <strong>
                  <span>Services</span>
                </strong>
                <span>).</span>
              </p>
              <h1
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>4.</span>
                <span style={{ width: '14.94pt', display: 'inline-block' }}> </span>
                <span>SUSPENSION &amp; TERMINATION</span>
              </h1>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>4.1</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>This Agreement will apply for the duration of each and every relevant Licence Period.</span>
                <span> </span>
              </p>
              <p
                style={{
                  margin: '6pt 6pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>4.2</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  This Agreement and the Licence and the User’s rights granted under this Agreement may be terminated or
                  suspended at any time by Avocados Australia or Export Connect immediately on the provision of written
                  notice to the Primary User if:
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.15pt 6pt 49.65pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(a)</span>
                <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>
                <span>
                  any User is in breach of this Agreement or a Third-Party Licence (as that term is defined in{' '}
                </span>
                <strong>
                  <span>clause 10.1</span>
                </strong>
                <span>);</span>
              </p>
              <p
                style={{
                  margin: '6pt 5.95pt 6pt 49.65pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(b)</span>
                <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>
                <span>
                  the Primary User becomes insolvent, bankrupt, is wound up, or has an administrator, liquidator or
                  receiver appointed over it or its assets;{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.9pt 6pt 49.65pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(c)</span>
                <span style={{ width: '10.81pt', display: 'inline-block' }}> </span>
                <span>
                  another agreement between the Primary User or a relevant Authorised User and Avocados Australia
                  permits Avocados Australia to terminate this Agreement; or
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.9pt 6pt 49.65pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(d)</span>
                <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>
                <span>
                  the version of the Portal in respect of which the Primary User’s Licence has been granted is no longer
                  offered or supported by Avocados Australia or Export Connect.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.9pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>4.3</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Where the Primary User is granted an Ongoing Licence, the Primary User may terminate that Ongoing
                  Licence on not less than 30 days’ written notice to Avocados Australia, subject to any restrictions or
                  conditions set out in the applicable Services Agreement.{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.9pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>4.4</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Suspension of the User’s Licence may result in either Avocados Australia and Export Connect completely
                  disabling the User’s use of the Portal and/or disabling certain functionality which would only be
                  accessible to the User if their Licence had not been suspended.
                </span>
                <span> </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.9pt 6pt 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>4.5</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>If a Licence is suspended in whole or in part under </span>
                <strong>
                  <span>clause 4.2</span>
                </strong>
                <span>, Avocados Australia may, at its sole election:</span>
              </p>
              <p
                style={{
                  margin: '6pt 5.9pt 6pt 49.65pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(a)</span>
                <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>
                <span>end that suspension if and when the relevant breach is cured; or</span>
              </p>
              <p
                style={{
                  margin: '6pt 5.9pt 6pt 49.65pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(b)</span>
                <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>
                <span>
                  provide notice of termination at any time if the breach has not been remedied, or alternatively
                  disable the use of the Portal,
                </span>
              </p>
              <p style={{ margin: '6pt 5.9pt 6pt 28.35pt', textAlign: 'justify', lineHeight: '108%' }}>
                <span>
                  and the User agrees that Avocados Australia and Export Connect will not incur any liability in respect
                  of the failure to supply access to the Portal (or allow the User to exercise any other rights in
                  respect of the Portal) during any period in which the Licence is suspended.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>4.6</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>Upon the termination of this Agreement:</span>
              </p>
              <p
                style={{
                  margin: '6pt 6.1pt 6pt 49.65pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(a)</span>
                <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>
                <span>
                  Avocados Australia and Export Connect may disable the User’s use of the Portal and/or disable certain
                  functionality which is only available to Users with a Licence;{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.1pt 6pt 49.65pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(b)</span>
                <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>
                <span>
                  the Primary User will be obligated to immediately pay any Charges owing to Avocados Australia;
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.1pt 6pt 49.65pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(c)</span>
                <span style={{ width: '10.81pt', display: 'inline-block' }}> </span>
                <span>
                  the User will, and will procure that any Authorised User will, immediately cease using the Portal;
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.65pt 6pt 49.65pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(d)</span>
                <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>
                <span>
                  the User will delete or destroy, or where specified by Avocados Australia or Export Connect, return to
                  Avocados Australia, any intellectual property or confidential information of Avocados Australia which
                  may be in the possession or control of the User;
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 5.6pt 6pt 49.65pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(e)</span>
                <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>
                <span>
                  any licence keys, passwords, or access rights to the Portal provided to the User will be immediately
                  deactivated and destroyed by Avocados Australia and Export Connect; and
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.15pt 6pt 49.65pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(f)</span>
                <span style={{ width: '12.81pt', display: 'inline-block' }}> </span>
                <span>
                  nothing herein shall be construed to release either party from any obligation that arose prior to the
                  effective date of such termination.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>4.7</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  If the Licence is terminated, the User may not use the Portal, or any services provided by the Portal,
                  without obtaining a new Licence from Avocados Australia or Export Connect. Avocados Australia may in
                  its sole discretion refuse to grant a Licence to the User.
                </span>
                <span> </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span style={{ fontFamily: 'Arial' }}>4.8</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <strong>
                  <span style={{ lineHeight: '108%' }}>Clauses</span>
                </strong>
                <a href="#_heading_h.26in1rg" style={{ textDecoration: 'none' }}>
                  <strong>
                    <span style={{ lineHeight: '108%', color: '#000000' }}></span>
                  </strong>
                  <strong>
                    <span style={{ lineHeight: '108%', color: '#000000' }}>5, 8, 9, 10,</span>
                  </strong>
                </a>
                <strong>
                  <span style={{ lineHeight: '108%' }}> 12 </span>
                </strong>
                <span style={{ lineHeight: '108%' }}>and </span>
                <strong>
                  <span style={{ lineHeight: '108%' }}>13 </span>
                </strong>
                <span style={{ lineHeight: '108%' }}>
                  of this Agreement will survive the termination of this Agreement.
                </span>
              </p>
              <h1
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>5.</span>
                <span style={{ width: '14.94pt', display: 'inline-block' }}> </span>
                <span>CONFIDENTIALITY &amp; PRIVACY </span>
              </h1>
              <p
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',
                  textIndent: '-21.25pt',
                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span style={{ fontFamily: 'Arial' }}>5.1</span>
                <span style={{ width: '8.74pt', display: 'inline-block' }}> </span>
                <span style={{ lineHeight: '108%' }}>
                  Avocados Australia will keep confidential all information provided by or on behalf of a User that is
                  marked as confidential or should otherwise reasonably be presumed to be confidential and will only use
                  or disclose such confidential information: (a) for the purpose of providing or procuring services
                  under this Agreement; (b) with the consent of the User; (c) in anonymized and aggregated form; or (d)
                  as may otherwise be required or permitted by law. The User must keep confidential all information
                  provided or made available by Avocados Australia and Export Connect in connection with the Portal or
                  Services, including but not limited to the Portal Data and the details of any Services Agreement, and
                  must use and disclose such information only for the purposes of accessing and enjoying the Portal (or
                  other purposes only with Avocados Australia’s prior written consent) and otherwise in accordance with
                  the terms of this Agreement.
                </span>{' '}
                <span style={{ lineHeight: '108%' }}>
                  Save to the extent that the Primary User is expressly permitted under a Services Agreement or with
                  Avocados Australia’s prior written consent to publish or communicate any Portal Data outside of the
                  Primary User’s or User’s own organisation, the Portal Data is and remains (as between the User and
                  Avocados Australia) Confidential Information of Avocados Australia and Export Connect, even if such
                  information is otherwise in part or whole available from other sources or in the public domain. A User
                  must not download, save, share, or republish Portal Data save without Avocados Australia’s specific
                  prior written consent.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',
                  textIndent: '-21.25pt',
                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>5.2</span>
                <span style={{ width: '8.74pt', display: 'inline-block' }}> </span>
                <span>Avocados Australia and Export Connect will comply with the applicable provisions of the </span>
                <em>
                  <span>Privacy Act 1988 </span>
                </em>
                <span>(Cth), the </span>
                <em>
                  <span>SPAM Act 2003 </span>
                </em>
                <span>
                  (Cth), and any other applicable law, in dealing with any personal information (as that term is defined
                  in the{' '}
                </span>
                <em>
                  <span>Privacy Act 1988 </span>
                </em>
                <span>(Cth)) or other identifying information provided by a User or a third party about a User (</span>
                <strong>
                  <span>Personal Information</span>
                </strong>
                <span>
                  ). Avocados Australia and Export Connect will at all times collect, use, hold, destroy, and disclose
                  Personal Information in accordance with its privacy policy (available via Avocados Australia’s
                  website) and applicable law.{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',
                  textIndent: '-21.25pt',
                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>5.3</span>
                <span style={{ width: '8.74pt', display: 'inline-block' }}> </span>
                <span>
                  The User undertakes and continuously warrants to Avocados Australia and Export Connect that:
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.1pt 6pt 42.55pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(a)</span>
                <span style={{ width: '3.2pt', display: 'inline-block' }}> </span>
                <span>
                  any Personal Information supplied to the Licensor in connection with this Agreement has been
                  collected, used, stored, and disclosed by the User (or any other person by its authority) in
                  compliance with all applicable laws and with the consent of any individual to whom the Personal
                  Information relates;{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.1pt 6pt 42.55pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(b)</span>
                <span style={{ width: '3.2pt', display: 'inline-block' }}> </span>
                <span>
                  the individual to whom the Personal Information relates has been made aware of Avocados Australia and
                  Export Connect’s identity, of how to contact Avocados Australia and Export Connect, of the nature of
                  Avocados Australia’s role in the supply of the Portal and Services, and of all other matters of which
                  Avocados Australia has expressly required that the person be informed, or of which that Avocados
                  Australia or User is required to inform a person about whom it collects Personal Information under
                  applicable law and in accordance with best practice; and
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.1pt 6pt 42.55pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>(c)</span>
                <span style={{ width: '3.71pt', display: 'inline-block' }}> </span>
                <span>
                  Avocados Australia and Export Connect is authorised, either by consent of the individual or by law, to
                  collect the Personal Information from the User and to use the Personal Information for the supply of
                  the Portal and Services and as contemplated by this Agreement.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',
                  textIndent: '-28.35pt',
                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>5.4</span>
                <span style={{ width: '15.84pt', display: 'inline-block' }}> </span>
                <span>
                  In respect of any data, images, information, material, or intellectual property uploaded or created by
                  or on behalf of a User for the purposes of using the Portal (
                </span>
                <strong>
                  <span>User Data</span>
                </strong>
                <span>
                  ), the User irrevocably consents to Avocados Australia and Export Connect collecting, using, storing,
                  and disclosing User Data in accordance with this Agreement and Export Connect’s Privacy Policy. The
                  User agrees, warrants and represents that such User Data is not in any way unlawful, indecent, or
                  likely to breach any applicable law and that Avocados Australia’s use, storage, and disclosure of that
                  User Data as contemplated by this Agreement will not breach any applicable law.
                </span>
              </p>
              <h1
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>6.</span>
                <span style={{ width: '14.94pt', display: 'inline-block' }}> </span>
                <span>SECURITY</span>
              </h1>
              <p
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',
                  textIndent: '-21.25pt',
                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>6.1</span>
                <span style={{ width: '8.74pt', display: 'inline-block' }}> </span>
                <span>
                  In uploading any otherwise communicating to Avocados Australia any User Data, whether via the User’s
                  use of the Portal or other channels, or accessing or downloading Portal Data, the User acknowledges
                  and agrees that:
                </span>
              </p>
              <ol type="a" className="awlist2" style={{ margin: '0pt', paddingLeft: '0pt' }}>
                <li
                  style={{
                    margin: '6pt 5.55pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',

                    fontSize: '8.5pt',
                  }}
                >
                  <span style={{ width: '10.91pt', display: 'inline-block' }}> </span>
                  <span style={{ lineHeight: '108%' }}>
                    User Data and Portal will be transmitted over the internet and any other network used by the Portal,
                    which may include transmission to servers located in other states or countries;
                  </span>
                </li>
                <li
                  style={{
                    margin: '6pt 6.15pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',

                    fontSize: '8.5pt',
                  }}
                >
                  <span style={{ width: '10.91pt', display: 'inline-block' }}> </span>
                  <span style={{ lineHeight: '108%' }}>
                    while Avocados Australia will take all reasonable security precautions, the transmission, upload,
                    download, or storage of User Data via the Portal is not guaranteed to be, and may not be, secure;
                  </span>
                </li>
                <li
                  style={{
                    margin: '6pt 5.65pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',

                    fontSize: '8.5pt',
                  }}
                >
                  <span style={{ width: '11.39pt', display: 'inline-block' }}> </span>
                  <span style={{ lineHeight: '108%' }}>
                    transmitting, uploading, or downloading User Data or Portal Data via the Portal may render the
                    User’s systems vulnerable to viruses, malware, or other forms of attack that are beyond the
                    reasonable control of Avocados Australia; and
                  </span>
                </li>
                <li
                  style={{
                    margin: '6pt 5.65pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',

                    fontSize: '8.5pt',
                  }}
                >
                  <span style={{ width: '10.91pt', display: 'inline-block' }}> </span>
                  <span style={{ lineHeight: '108%' }}>
                    the User is at all times responsible for the security of its own systems, and the security of the
                    User Data and any copies or back-ups of User Data or Portal Data that the User is permitted to make
                    or download in accordance with this Agreement.{' '}
                  </span>
                </li>
              </ol>
              <p
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',
                  textIndent: '-21.25pt',
                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>6.2</span>
                <span style={{ width: '8.74pt', display: 'inline-block' }}> </span>
                <span>
                  Avocados Australia and Export Connect will comply with any laws requiring them to notify the User or
                  any other person regarding any notifiable data breach. Otherwise, to the maximum extent permitted by
                  law, Avocados Australia and Export Connect will not be liable or responsible for protecting the
                  security of any User Data or Portal Data transmitted, uploaded, or downloaded via the Portal, or for
                  any loss, cost, expense or damage suffered by the User as a result of any unauthorised access or use
                  of the User Data, Portal Data, Portal, or Avocados Australia’s systems.
                </span>
              </p>
              <h1
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>7.</span>
                <span style={{ width: '14.94pt', display: 'inline-block' }}> </span>
                <span>UPDATES, UPGRADES, AND SUPPORT</span>
              </h1>
              <p
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',
                  textIndent: '-21.25pt',
                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span style={{ fontFamily: 'Arial' }}>7.1</span>
                <span style={{ width: '8.74pt', display: 'inline-block' }}> </span>
                <span style={{ lineHeight: '108%' }}>Subject to </span>
                <strong>
                  <span style={{ lineHeight: '108%' }}>clause 7.2</span>
                </strong>
                <a href="#_heading_h.35nkun2" style={{ textDecoration: 'none' }}>
                  <strong>
                    <span style={{ lineHeight: '108%', color: '#000000' }}>,</span>
                  </strong>
                </a>
                <span style={{ lineHeight: '108%' }}>
                  {' '}
                  Export Cibbect is under no obligation to provide updates, upgrades or new releases for the Portal (
                </span>
                <strong>
                  <span style={{ lineHeight: '108%' }}>Upgrades</span>
                </strong>
                <span style={{ lineHeight: '108%' }}>
                  ), Portal maintenance, support services, or data backup services.{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>7.2</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Export Connect will only provide Upgrades and Support to the User if the Primary User has purchased
                  the right to receive Upgrades and Support from Export Connect in an applicable Services Agreement. If
                  the User does purchase Upgrades and/or Support from Export Connect, then the terms of providing those
                  Upgrades and Support will be separately specified by Export Connect in the Services Agreement or
                  otherwise notified by Avocados Australia in writing.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>7.3</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  This Agreement will, unless specified by Export Connect Australia to the contrary in writing, apply to
                  all Upgrades supplied by Export Connect to the User (and a reference to the “Portal” will be deemed to
                  include a reference to each Upgrade which Export Connect supplies to the User).
                </span>
              </p>
              <h1
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>8.</span>
                <span style={{ width: '14.94pt', display: 'inline-block' }}> </span>
                <span>WARRANTIES &amp; LIABILITY</span>
              </h1>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>8.1</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  The Primary User must not, and must not permit its Authorised Persons to, use the Portal in any way
                  that might injure, damage, or otherwise breach the rights of any person or contravene any applicable
                  laws.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>8.2</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>The User acknowledges that:</span>
              </p>
              <ol type="a" className="awlist3" style={{ margin: '0pt', paddingLeft: '0pt' }}>
                <li
                  style={{
                    margin: '6pt 5.65pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',

                    fontSize: '10pt',
                  }}
                >
                  <span style={{ width: '9.08pt', display: 'inline-block' }}> </span>
                  <span style={{ lineHeight: '108%' }}>
                    Avocados Australia and Export Connect makes no warranties that the Portal is error-free, that the
                    Portal will be accessible on the User’s systems, or that the User’s access to the Portal will be
                    uninterrupted; and{' '}
                  </span>
                </li>
                <li
                  style={{
                    margin: '6pt 5.8pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',

                    fontSize: '10pt',
                  }}
                >
                  <span style={{ width: '9.08pt', display: 'inline-block' }}> </span>
                  <span style={{ lineHeight: '108%' }}>
                    Avocados Australia and Export Connect has not made and will not make any other express or implied
                    warranties in relation to the Portal, any Services or Additional Services, or any other goods or
                    services provided by Avocados Australia, Export Connect or any third party in connection with the
                    Portal or Services, other than those warranties expressly contained in this Agreement. Any term that
                    would be implied into this Agreement, including without limitation any condition or warranty, is
                    hereby excluded to the maximum extent permitted by law.
                  </span>
                </li>
              </ol>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>8.3</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Export Connect provides the Portal “as is” and, to the maximum extent permitted by law, Export Connect
                  or Avocados Australia will not be liable for or in respect of any loss, damage, expense, cost or claim
                  by or against any User (whether contractual, tortious, statutory, or otherwise), whether direct or
                  indirect, special, incidental, or consequential (including but not limited to, any loss of profits,
                  contracts, revenue or data), arising out of or in connection with the provision of the Portal or
                  Services or the provision of any other goods or services under this Agreement, howsoever caused.{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>8.4</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  The Portal Data is provided for informational purposes only and Avocados Australia and Export Connect
                  does not warrant that the Portal Data is correct or complete or should be relied upon by a User in the
                  absence of or as a substitute for direct and/or independent verification and assessment of relevant
                  matters. Avocados Australia and Export Connect does not warrant that use of the Portal or any Portal
                  Data will provide the User or Primary User with any benefit or advantage (commercial or otherwise),
                  including but not limited to increases in sales or marketing reach, compliance with applicable law, or
                  protection against the consequences of legal non-compliance, negligence, or wilful misconduct or
                  wrongdoing by any person. The Primary User is responsible for monitoring and assessing all use of the
                  Portal and ensuring that such use is compliant with applicable law and best practice. Portal Data of,
                  about, or produced by third parties (including any Intellectual Property therein) is prepared by
                  Avocados Australia and Export Connect for informational purposes and the Clients’ (and/or its Users’)
                  internal use only. Such Portal Data may have been obtained from public sources or with consent of the
                  relevant third party but Avocados Australia and Export Connect does not warrant or represent that the
                  Client has any title or right, licence, or authority to reproduce, publish, or communicate that Portal
                  Data or Intellectual Property or permit any other person to do so.{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',
                  textIndent: '-28.35pt',
                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>8.5</span>
                <span style={{ width: '15.84pt', display: 'inline-block' }}> </span>
                <span>
                  Avocados Australia and Export Connect provides advice and recommendations, if any, based on
                  information available at the time Portal Data is published.
                </span>
                <span> </span>
                <span>
                  Avocados Australia and Export Connect does not provide legal advice, or give or make any express or
                  implied guarantees, warranties, or other representations regarding such advice being appropriate for
                  any or all Users’ purposes. The User must conduct reasonable investigations and inquiries to determine
                  if the Portal and the Portal Data are appropriate for the User’s needs and intended uses. Reasonable
                  efforts have been made to ensure that the Portal Data is accurate and complete at the time of supply
                  or upload, but neither Avocados Australia, Export Connect, nor any other person provides any
                  warranties or guarantees to that effect. Users acknowledge as a condition of use of the Portal that
                  Portal Data often relates to matters outside of Avocados Australia and Export Connect’s control and is
                  subject to change at any time.{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>8.6</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>If the </span>
                <em>
                  <span>Competition and Consumer Act 2010 </span>
                </em>
                <span>
                  (Cth) (or analogous legislation) applies to this Agreement and prohibits the exclusion of certain
                  liability but permits the limitation of that liability, then that liability of Avocados Australia and
                  Export Connect is limited, at the option of Avocados Australia and Export Connect, to:
                </span>
              </p>
              <ol type="a" className="awlist4" style={{ margin: '0pt', paddingLeft: '0pt' }}>
                <li
                  style={{
                    margin: '6pt 6.15pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>in the case of goods, any one or
                  more of the following:
                  <ol
                    type="i"
                    className="awlist5"
                    style={{ marginRight: '0pt', marginLeft: '0pt', paddingLeft: '0pt' }}
                  >
                    <li style={{ margin: '6pt 6.15pt 6pt 21.25pt', textIndent: '-21.25pt' }}>
                      <span style={{ width: '13.26pt', display: 'inline-block' }}> </span>
                      the replacement of the goods or the supply of equivalent goods;
                    </li>
                    <li style={{ margin: '6pt 6.15pt 6pt 21.25pt', textIndent: '-21.25pt' }}>
                      <span style={{ width: '11.26pt', display: 'inline-block' }}> </span>
                      the repair of the goods;
                    </li>
                    <li style={{ margin: '6pt 6.15pt 6pt 21.25pt', textIndent: '-21.25pt' }}>
                      <span style={{ width: '9.26pt', display: 'inline-block' }}> </span>
                      the payment of the cost of replacing the goods or of acquiring equivalent goods; or
                    </li>
                    <li style={{ margin: '6pt 6.15pt 6pt 21.25pt', textIndent: '-21.25pt' }}>
                      <span style={{ width: '8.76pt', display: 'inline-block' }}> </span>
                      the payment of the cost of having the goods repaired; and
                    </li>
                  </ol>
                </li>
                <li
                  style={{
                    margin: '6pt 6.15pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>in the case of services:
                  <ol
                    type="i"
                    className="awlist5"
                    style={{ marginRight: '0pt', marginLeft: '0pt', paddingLeft: '0pt' }}
                  >
                    <li style={{ margin: '6pt 6.15pt 6pt 21.25pt', textIndent: '-21.25pt' }}>
                      <span style={{ width: '13.26pt', display: 'inline-block' }}> </span>
                      the supplying of the services again; or
                    </li>
                    <li style={{ margin: '6pt 6.15pt 6pt 21.25pt', textIndent: '-21.25pt' }}>
                      <span style={{ width: '11.26pt', display: 'inline-block' }}> </span>
                      the payment of the cost of having the services supplied again.
                    </li>
                  </ol>
                </li>
              </ol>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>8.7</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  The maximum liability of Avocados Australia and Export Connectunder this Agreement for any matter
                  which cannot otherwise be excluded as described in this Agreement, will be limited in aggregate (to
                  the maximum extent permitted by law) to the lesser of:
                </span>
              </p>
              <ol type="a" className="awlist6" style={{ margin: '0pt', paddingLeft: '0pt' }}>
                <li
                  style={{
                    margin: '6pt 6.15pt 6pt 83.9pt',
                    textIndent: '-55.55pt',
                    textAlign: 'justify',
                    lineHeight: '108%',

                    listStylePosition: 'inside',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>the total Charges paid by the User;
                  or
                </li>
                <li
                  style={{
                    margin: '6pt 6.15pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>AUD $10.
                </li>
              </ol>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>8.8</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  The Primary User indemnifies Avocados Australia and Export Connect, its agents, officers and employees
                  against any loss, cost, expense or damage (including legal costs on a full indemnity basis) which is
                  suffered or incurred by Avocados Australia and Export Connect, its agents, officers or employees as a
                  direct or indirect result of:
                </span>
              </p>
              <ol type="a" className="awlist7" style={{ margin: '0pt', paddingLeft: '0pt' }}>
                <li
                  style={{
                    margin: '6pt 6.15pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>any breach of this Agreement by the
                  User or its Related Parties; or
                </li>
                <li
                  style={{
                    margin: '6pt 6.15pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>any third-party action, claim,
                  demand or proceedings instituted against Avocados Australia and Export Connect as a result of the use
                  of the Portal by the User or its Related Parties.
                </li>
              </ol>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>8.9</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Any of the terms and conditions of this Agreement which limit or exclude any term, condition or
                  warranty, express or implied, or the liability of Avocados Australia and Export Connect will apply to
                  the extent permitted by law and will not be construed as excluding, qualifying or limiting the User’s
                  statutory rights or remedies arising by virtue of the breach of any implied term of this Agreement
                  where such exclusion, qualification or limitation would be prohibited by legislation.
                </span>
              </p>
              <h1
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>9.</span>
                <span style={{ width: '14.94pt', display: 'inline-block' }}> </span>
                <span>INTELLECTUAL PROPERTY </span>
              </h1>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>9.1</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Nothing in this Agreement affects the ownership of any intellectual property or associated rights
                  owned or licensed by any person prior to the grant of any Licence hereunder.{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>9.2</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Export Connect retains ownership of the Portal and all intellectual property, including any rights in
                  copyright, moral rights, inventions (including patents), trademarks, designs, circuit layouts (whether
                  or not registered or registrable), which subsist in the Portal, including any Portal Data or other
                  data, material, or information created by or on behalf Avocados Australia and Export Connect and
                  displayed or made available via the Portal at all times. Apart from the Licence granted herein, all
                  such rights are reserved by and for Export Connects exclusive benefit.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>9.3</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  Subject to payment of the Charges and compliance with this Agreement, Export Connect grants to the
                  Primary User a perpetual, irrevocable, transferrable, royalty-free, world-wide licence (with right of
                  sublicense in accordance with this Agreement only) to use the Portal Data for its internal business
                  purposes.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>9.4</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  The User owns retains ownership of any User Data, save to the extent that the User Data incorporates
                  or consists of Portal Data. The User grants (or will procure the grant) to Avocados Australia and
                  Export Connect a perpetual, irrevocable, transferrable, royalty-free, world-wide licence (with right
                  of sublicense) to use, edit, modify, transform, transmit, broadcast, and disclose the User Data for
                  the purpose of providing the User with the Services, including any necessary consent or waiver
                  permitting such use (even where a relevant action or omission by Avocados Australia and Export Connect
                  would otherwise constitute an infringement of a persons rights of attribution or integrity, or against
                  false attribution).
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>9.5</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>Except to the extent permissible under the </span>
                <em>
                  <span>Copyright Act 1968</span>
                </em>
                <span>
                  {' '}
                  (Cth), and to the extent strictly necessary to access and use the Portal, it is a condition of the
                  Licence that the User must not attempt to copy, reproduce, modify, transform, decompile,
                  reverse-engineer, introduce malicious viruses, software or code, or otherwise interfere with the
                  function of the Portal (or any source code or programming comprising the Portal).{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>9.6</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>The User represents and warrants to Avocados Australia and Export Connect that: </span>
              </p>
              <ol type="a" className="awlist8" style={{ margin: '0pt', paddingLeft: '0pt' }}>
                <li
                  style={{
                    margin: '6pt 5.6pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>the User has (or will procure) all
                  necessary right, authority and capacity to enter-into, execute, and perform its obligations under this
                  Agreement (including the grant of licence to Avocados Australia and Export Connect); and
                </li>
                <li
                  style={{
                    margin: '6pt 5.6pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>
                  Avocados Australia and Export Connect’s exercise of its rights to the User Data will not infringe the
                  intellectual property rights or moral rights of any person.
                </li>
              </ol>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>9.7</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>
                  The User continuously releases and indemnifies Avocados Australia and Export Connect for and against
                  any loss, cost (including legal costs on a full indemnity basis), damage, claim (including for
                  negligence), or expense suffered or incurred by Avocados Australia and Export Connect and arising in
                  connection with a claim that the User Data, or use of the User Data, infringes any persons rights
                  (including, but not limited to, intellectual property or moral rights) or defames, injures, harms, or
                  offends any person.
                </span>
              </p>
              <h1
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>10.</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>COMPLIANCE WITH THIRD-PARTY LICENCES</span>
              </h1>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>10.1</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>
                  The Portal may incorporate components licensed to Avocados Australia and Export Connect by third
                  parties, which may be subject to their own End User Licence Agreements (
                </span>
                <strong>
                  <span>Third-Party Licences</span>
                </strong>
                <span>).</span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>10.2</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>
                  The User agrees that the use of the Portal, in addition to this Agreement, will be governed by any
                  terms and conditions specified by any Third-Party Licence that applies to the Portal, including but
                  not limited to those appended to this Agreement or separately notified to the User or Primary User.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span style={{ fontFamily: 'Arial' }}>10.3</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span style={{ lineHeight: '108%' }}>
                  The User agrees to be bound by and observe all terms and conditions of any Third-Party Licence and
                  acknowledges that any breach of a Third-Party Licence will entitle Avocados Australia and Export
                  Connect to terminate the Licence and exercise its rights under{' '}
                </span>
                <strong>
                  <span style={{ lineHeight: '108%' }}>clause </span>
                </strong>
                <a href="#_heading_h.1t3h5sf" style={{ textDecoration: 'none' }}>
                  <strong>
                    <span style={{ lineHeight: '108%', color: '#000000' }}>4.</span>
                  </strong>
                </a>
              </p>
              <h1
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>11.</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>AMENDING THIS AGREEMENT</span>
              </h1>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>11.1</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>
                  Avocados Australia and Export Connect may amend any of the terms of this Agreement by providing
                  written notice to the Primary User of such amendments and/or displaying such amendments or an amended
                  copy of this Agreement to the Primary User during its use of the Portal. Without limiting the methods
                  by which the Primary User may accept such amended terms, the Primary User acknowledges and agrees that
                  its ongoing use of the Portal after it is made aware of any amended terms to this Agreement will
                  constitute its acceptance of such amended terms.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>11.2</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>
                  If the Primary User does not agree to any amendments made by Avocados Australia to the terms of this
                  Agreement, then the Primary User must immediately cease any further use of the Portal, and notify
                  Avocados Australia in writing of its intention to terminate the Licence. In such circumstances, the
                  Primary User may be eligible for a refund or partial refund of any Charges paid to Avocados Australia
                  and Export Connect in respect of the Portal (subject to any conditions which Avocados Australia and
                  Export Connect may put in place in respect of paying such a refund).
                </span>
              </p>
              <h1
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>12.</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>DISPUTES</span>
              </h1>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>12.1</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>
                  A User must not commence court proceedings in relation to a complaint or dispute with Avocados
                  Australia and Export Connect in connection with the Portal (
                </span>
                <strong>
                  <span>Complaint</span>
                </strong>
                <span>) until it has exhausted the procedures in this </span>
                <strong>
                  <span>clause 13.1</span>
                </strong>
                <span>. If a Complaint arises:</span>
              </p>
              <ol type="a" className="awlist9" style={{ margin: '0pt', paddingLeft: '0pt' }}>
                <li
                  style={{
                    margin: '6pt 5.65pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>the User must first notify Avocados
                  Australia and Export Connect of the nature of the complaint or dispute in writing via the contact
                  details listed on Avocados Australia’s website;
                </li>
                <li
                  style={{
                    margin: '6pt 5.65pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>
                  Avocados Australia and Export Connect will, to the extent reasonably possible, consider and respond to
                  the User’s notice of Complaint within a reasonable period of time;
                </li>
                <li
                  style={{
                    margin: '6pt 5.65pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.81pt', display: 'inline-block' }}> </span>the User must respond to any
                  communication from Avocados Australia in respect of the Complaint as soon as possible, including
                  providing any additional information or material that may be reasonably required by Avocados Australia
                  and Export Connect;
                </li>
                <li
                  style={{
                    margin: '6pt 5.65pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>the User, Avocados Australia and
                  Export Connect will use best efforts to resolve the Complaint in a timely manner; and
                </li>
                <li
                  style={{
                    margin: '6pt 5.65pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>if the Complaint is not resolved
                  within 90 days of Avocados Australia and Export Connect first receiving notice of the Complaint,
                  Avocados Australia and Export Connect may instruct the President of the Law Institute of Victoria to
                  appoint an independent mediator to resolve the dispute by mediation and the parties must participate
                  in the mediation in good faith and equally share the costs of the mediation.
                </li>
              </ol>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>12.2</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>Nothing in this </span>
                <strong>
                  <span>clause 12 </span>
                </strong>
                <span>
                  will prevent Avocados Australia, Export Connect or User seeking urgent or injunctive relief from a
                  Court in relation to a matter arising under this Agreement.
                </span>
                <span> </span>
              </p>
              <h1
                style={{
                  margin: '6pt 6.15pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>13.</span>
                <span style={{ width: '9.94pt', display: 'inline-block' }}> </span>
                <span>GENERAL</span>
              </h1>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>13.1</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>In the interpretation of this Agreement, unless the contrary intention appears:</span>
              </p>
              <ol type="a" className="awlist10" style={{ margin: '0pt', paddingLeft: '0pt' }}>
                <li
                  style={{
                    margin: '6pt 5.6pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>a reference to this Agreement means
                  a reference to an agreement between Avocados Australia, Export Connect and the User on the terms and
                  conditions of this document and includes an amendment or supplement to, or replacement or novation of
                  this Agreement;
                </li>
                <li
                  style={{
                    margin: '6pt 5.55pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>a reference to a person includes a
                  reference to a corporation, firm, association or other entity, and vice versa;
                </li>
                <li
                  style={{
                    margin: '6pt 6.15pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.81pt', display: 'inline-block' }}> </span>the singular includes the plural
                  and vice versa;
                </li>
                <li
                  style={{
                    margin: '6pt 6.15pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>a reference to any gender includes
                  a reference to all other genders;
                </li>
                <li
                  style={{
                    marginTop: '6pt',
                    marginLeft: '49.65pt',
                    marginBottom: '6pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>a reference to any legislation or
                  to any provision of any legislation includes a reference to any modification or re-enactment of or any
                  provisions substituted for such legislation or provisions;
                </li>
                <li
                  style={{
                    margin: '6pt 6pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '12.81pt', display: 'inline-block' }}> </span>an agreement, representation or
                  warranty made by two or more persons is made by them jointly and by each of them severally;
                </li>
                <li
                  style={{
                    margin: '6pt 6.05pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>where an expression is defined,
                  another part of speech or grammatical form of that expression has a corresponding meaning; and
                </li>
                <li
                  style={{
                    margin: '6pt 5.7pt 6pt 49.65pt',

                    textAlign: 'justify',
                    lineHeight: '108%',
                  }}
                >
                  <span style={{ width: '10.3pt', display: 'inline-block' }}> </span>
                  headings are inserted for convenience only and do not affect the interpretation of this Agreement.
                </li>
              </ol>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>13.2</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>
                  In the case of a Primary User, unless otherwise requested in writing by the Primary User, Avocados
                  Australia and Export Connect may use the Primary User’s corporate identity (if applicable) as part of
                  promoting the Portal in the market place.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>13.3</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>
                  This Agreement supersedes all prior representations, arrangements, understandings and agreements
                  between the parties relating to the subject matter of this Agreement (save for any written agreement
                  concerning the supply and/or license of information or intellectual property to Avocados Australia and
                  Export Connect which is expressed to override this Agreement) and sets forth the entire and exclusive
                  agreement and understanding between the parties relating to the subject matter of this Agreement. The
                  terms of this Agreement may only be varied by Export Connect as provided in this Agreement, or by
                  subsequent written agreement the parties.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>13.4</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>
                  A provision of or a right created under this Agreement may not be waived except in writing signed by
                  the party or parties to be bound by the waiver. No single or partial exercise by any party of any
                  right, power or remedy under this Agreement will preclude any other or further exercise of that or any
                  other right, power or remedy. The rights, powers or remedies provided in this Agreement are cumulative
                  with and not exclusive of any rights, powers or remedies provided independently of this Agreement.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>13.5</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>
                  If any provision of this Agreement is judged invalid or unenforceable for any reason whatsoever by a
                  court of competent jurisdiction, such invalidity or unenforceability (unless deletion of such
                  provision would materially adversely affect one of the parties) will not affect the operation or
                  interpretation of any other provision of this Agreement to the intent that the invalid or
                  unenforceable provision will be treated as severed from this Agreement.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>13.6</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>
                  Avocados Australia and Export Connect may assign, novate or otherwise transfer its rights and
                  obligations that arise under this Agreement. The User may not assign its rights or obligations that
                  arise under this Agreement without the prior written consent of Avocados Australia and Export Connect
                  (which may be withheld).
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>13.7</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>
                  The parties acknowledge and agree that no rule of construction applies to the disadvantage of a party
                  because that party was responsible for the preparation of this Agreement or part of it.
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>13.8</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>
                  Nothing in this agreement creates a relationship of employment, joint venture, partnership, or agent
                  or fiduciary and principal between the parties.{' '}
                </span>
              </p>
              <p
                style={{
                  margin: '6pt 6.3pt 20px 28.35pt',

                  textAlign: 'justify',
                  lineHeight: '108%',
                }}
              >
                <span>13.9</span>
                <span style={{ width: '4.93pt', display: 'inline-block' }}> </span>
                <span>
                  This Agreement is governed by, and must be construed in accordance with, the laws of the State of New
                  South Wales, Australia and the parties irrevocably submit to the exclusive jurisdiction of the courts
                  of the State of New South Wales, Australia.
                </span>
              </p>
            </div>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default TermsOfUse;
