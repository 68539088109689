/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { ActivityStoryItem } from './ActivityStoryItem/ActivityStoryItem';
import * as S from './ActivityStory.styles';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import styled from 'styled-components';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import moment from 'moment';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
// import { LoginUser, MakeAdmin } from '@app/api/users/ViewUsers.api';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { persistToken } from '@app/services/localStorage.service';
import { setUser } from '@app/store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import Model from '@app/components/Model';
import { Checkbox } from 'antd';
import type { CheckboxProps } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import PermissionPage from '../PermissionPage/PermissionPage';
import { MakeAdmin } from '@app/api/UserLogin/userlogin.api';
// import PermissionPage from '../PermissionPage/PermissionPage';

interface ModalState {
  isModalOpen: boolean;
  name?: string;
  _id?: string;
}
interface Props {
  item: data[];
}
const ButtonContainer = styled(DashboardCard)`
  margin-bottom: 20px;
`;
const LoadUserContainer = styled(BaseCol)`
  .ant-col:hover {
    cursor: pointer;
  }
`;
const CustomClaims = styled(BaseButton)`
  display: block;
  margin: 10px 0;
  width: 100%;
`;
const Impersonate = styled(BaseButton)`
  display: block;
  margin: 10px 0;
  width: 100%;
`;
const Apply = styled(BaseButton)`
  display: block;
  margin: 20px 0;
  color: white;
  background-color: #00b4a0;
  width: 100%;
`;
const Cancelbutton = styled(BaseButton)`
  display: block;
  margin: 10px 0;
  width: 100%;
`;
interface data {
  role: ReactNode;
  createdAt(createdAt: any): unknown;
  state: ReactNode;
  country_name: string;
  password: any;
  firstName: string;
  lastName: string;
  country: string;
  company: string;
  position: string;
  phoneNumber: number;
  lastActive: string;
  email: string;
  _id: string;
  created: Date;
}
type story = data[];
export const ActivityStory = ({ item }: Props): JSX.Element => {
  const [show, setshow] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [seleteditem, setSeleteditem] = useState<data>();
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false });
  const [status, setStatus] = useState<any>({ status: false });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const story: story = item ? item : [];
  const showModal = () => {
    setModalState({ ...modalState, isModalOpen: true });
  };

  const handleOk = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isModalOpen: false });
  };
  const activityStory = useMemo(
    () =>
      story?.map((item, index) => (
        <LoadUserContainer key={index} span={24}>
          <ActivityStoryItem item={item} setshow={setshow} setSelecteditem={setSeleteditem} />
        </LoadUserContainer>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [story],
  );
  // const handleImpersonateClick = async (id: string, userdata: any) => {
  //   try {
  //     setLoading(true);
  //     const data = await LoginUser(seleteditem?._id || '', userdata);
  //     dispatch(setUser(data?.data?.user));
  //     persistToken(data.data.token);
  //     navigate('/dashboard/your-subscriptions', { state: 'Welcome to exportal connect portal' });
  //   } catch (error) {
  //     console.error('Error occurred while logging in:', error);
  //     // Handle error here, such as displaying a message to the user
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const onChange: CheckboxProps['onChange'] = (e) => {
    setStatus({ status: e.target.checked });
  };
  const handleMakeADmin = async (id: string) => {
    try {
      setLoading(true);
      await MakeAdmin(id || '', status);
      // dispatch(setUser(data?.data?.user));
      // console.log('data', data);
      notificationController.success({ message: 'Custom claims updated' });
      setTimeout(
        () => notificationController.success({ message: 'New claims will be active the next time they sign in' }),
        3000,
      );
      handleCancel();
    } catch (error) {
      console.error('Error occurred while logging in:', error);
      // Handle error here, such as displaying a message to the user
    } finally {
      setLoading(false);
    }
  };
  // async () => await MakeAdmin(seleteditem?._id || ''
  return (
    <S.Wrapper>
      {/* <S.Title level={2}>{t('nft.activityStory')}</S.Title> */}
      {show ? (
        <div>
          <S.ActivityRow gutter={[26, 26]}>{activityStory}</S.ActivityRow>
          {activityStory?.length === 0 && <div style={{ margin: '0 auto' }}>No User Found</div>}
        </div>
      ) : (
        <>
          {seleteditem && (
            <div>
              <DashboardCard title={seleteditem?.fullName}>
                <BaseCol span={24}>
                  <S.Title>Name: {seleteditem?.fullName}</S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>Email: {seleteditem?.email}</S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>Country: {seleteditem?.country_name}</S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>Company: {seleteditem?.company}</S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>Position: {seleteditem?.position}</S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title> Role: {seleteditem?.role}</S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>Phone Number: {seleteditem?.phoneNumber}</S.Title>
                </BaseCol>

                <BaseCol span={24}>
                  <S.Status $color={'primary' || 'primary'}>
                    Created:{' '}
                    {seleteditem?.created != 'N/A'
                      ? moment(seleteditem?.created).format('ddd MMM DD YYYY HH:mm:ss')
                      : 'N/A'}
                  </S.Status>
                </BaseCol>
                <ButtonContainer>
                  <div style={{ width: '50%', margin: '0 auto' }}>
                    {/* <Model></> */}
                    <Model
                      title="Add custom claims"
                      open={modalState.isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      content={
                        <div style={{ width: '65%', margin: '0 auto', textAlign: 'center' }}>
                          <Checkbox onChange={onChange}>Make admin</Checkbox>
                          <Apply loading={loading} onClick={() => handleMakeADmin(seleteditem?._id)}>
                            Apply
                          </Apply>
                        </div>
                      }
                    />
                    <CustomClaims severity="warning" onClick={showModal}>
                      Add Custom Claims
                    </CustomClaims>
                    <Cancelbutton onClick={() => setshow(true)}>Cancel</Cancelbutton>
                  </div>
                </ButtonContainer>
              </DashboardCard>
              <PermissionPage userid={seleteditem._id} />
            </div>
          )}
        </>
      )}
    </S.Wrapper>
  );
};
