import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { UploadOutlined } from '@ant-design/icons';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './logo.styles';
import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import { EditFaviconChange, EditLogoChange, GetLogoChange } from '@app/api/LogoChange/LogoChange.api';

interface formData {
  status: boolean;
  Image: {
    file: {
      originFileObj: File;
    };
  };
}

const LogoChange = () => {
  const { t } = useTranslation();
  const [img, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [favloading, setfavLoading] = useState(false);

  const logoUploadProps = {
    name: 'logo',
    multiple: false,
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append('file', values?.logo?.file?.originFileObj);
    formdata.append('site_name', 'avocado_staging');

    try {
      await EditLogoChange(formdata as any);
      notificationController.success({ message: t('common.success') });
      fetchImage();
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const handleFaviconSubmit = async (values: any) => {
    setfavLoading(true);
    const formdata = new FormData();
    formdata.append('favicon', values?.favicon?.file?.originFileObj);
    formdata.append('site_name', 'avocado_staging');
    try {
      await EditFaviconChange(formdata as any);
      notificationController.success({ message: t('common.success') });
      fetchImage();
    } catch (err) {
      console.log('err', err);
    } finally {
      setfavLoading(false);
    }
  };

  const fetchImage = async () => {
    try {
      const response = await GetLogoChange();
      setImage(response.data[1]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <>
      <S.Wrapper>
        <div
          style={{
            borderRadius: '5px',
            padding: '35px',
            boxShadow: 'rgba(35, 78, 84, 0.61) 0px 0px 15px 1px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <div
            style={{
              borderRadius: '3px',
              padding: '20px',
              boxShadow: 'rgba(35, 78, 84, 0.61) 0px 0px 5px 1px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              width: '80%',
            }}
          >
            <h3 style={{ textAlign: 'center' }}>Change Site Logo</h3>
            <div style={{ margin: '20px 0px', display: 'flex', justifyContent: 'center' }}>
              <img style={{ height: '150px', width: '150px', objectFit: 'contain' }} src={img?.logo}></img>
            </div>
            <BaseForm onFinish={handleSubmit}>
              <Auth.FormItem name="logo" rules={[{ required: true, message: t('common.requiredField') }]}>
                <BaseUpload {...logoUploadProps} maxCount={1} accept="image/*">
                  <BaseButton icon={<UploadOutlined />}>Change Logo</BaseButton>
                </BaseUpload>
              </Auth.FormItem>

              {/* <EditOutlined /> */}

              <BaseForm.Item noStyle>
                <Auth.SubmitButton type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Auth.SubmitButton>
              </BaseForm.Item>
            </BaseForm>
          </div>

          <div
            style={{
              borderRadius: '3px',
              padding: '20px',
              boxShadow: 'rgba(35, 78, 84, 0.61) 0px 0px 5px 1px',
              width: '80%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <BaseForm onFinish={handleFaviconSubmit}>
              <h3 style={{ textAlign: 'center' }}>Change Favicon</h3>
              <div style={{ margin: '20px 0px', display: 'flex', justifyContent: 'center' }}>
                <img src={img?.favicon_value} style={{ height: '150px', width: '150px', objectFit: 'contain' }}></img>
              </div>

              <Auth.FormItem name="favicon" rules={[{ required: true, message: t('common.requiredField') }]}>
                <BaseUpload {...logoUploadProps} maxCount={1} accept="image/*">
                  <BaseButton icon={<UploadOutlined />}>Change Favicon</BaseButton>
                </BaseUpload>
              </Auth.FormItem>
              <BaseForm.Item noStyle>
                <Auth.SubmitButton type="primary" htmlType="submit" loading={favloading}>
                  Submit
                </Auth.SubmitButton>
              </BaseForm.Item>
            </BaseForm>
          </div>
        </div>
      </S.Wrapper>
    </>
  );
};

export default LogoChange;
