import React from 'react';
import * as S from './References.styles';
import { Link } from 'react-router-dom';
import { BaseDivider } from '../BaseDivider/BaseDivider';
import styled from 'styled-components';
// import { FacebookOutlined, GithubOutlined, LinkedinOutlined, TwitterOutlined } from '@ant-design/icons';
const BaseDividernew = styled(BaseDivider)`
  border-top: 1px solid #00000040;
`;
export const References: React.FC = () => {
  // const permissions = JSON.parse(localStorage.getItem('permissions') as string);
  // const Terms_of_Use = permissions?.pagePermissions?.find(
  //   (e: { page_name: string }) => e?.page_name === 'Terms_of_Use',
  // )?.permissions;
  // const Privacy_policy = permissions?.pagePermissions?.find(
  //   (e: { page_name: string }) => e?.page_name === 'Privacy_policy',
  // )?.permissions;
  return (
    <>
      <BaseDividernew />

      <S.ReferencesWrapper>
        <S.Text>Copyright 2024 Avocados. All rights reserved.</S.Text>
        {/* <S.Icons>
        <a href="https://github.com/altence/lightence-admin" target="_blank" rel="noreferrer">
          <GithubOutlined />
        </a>
        <a href="https://twitter.com/altence_team" target="_blank" rel="noreferrer">
          <TwitterOutlined />
        </a>
        <a href="https://www.facebook.com/groups/altence" target="_blank" rel="noreferrer">
          <FacebookOutlined />
        </a>
        <a href="https://linkedin.com/company/altence" target="_blank" rel="noreferrer">
          <LinkedinOutlined />
        </a>
      </S.Icons> */}
        <S.Icons>
          <Link to="/privacypolicy" style={{ marginRight: '10px', textDecoration: 'underline' }}>
            Privacy Policy
          </Link>
          <Link to="/termsofuse" style={{ textDecoration: 'underline' }}>
            Terms of Use
          </Link>
        </S.Icons>
      </S.ReferencesWrapper>
    </>
  );
};
