/* eslint-disable @typescript-eslint/no-explicit-any */
import { httpApi } from '@app/api/http.api';

export const MakeAdmin = (id: string, status: any): Promise<any> => {
  return httpApi.put<any>(`/user/${id}/userToAdmin`, status).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
export const LoginUser = (id: string, data: any): Promise<any> => {
  return httpApi.post<any>(`/user/${id}/newUserLogin`, data).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
export const UserSSO = (
  email: string,
  firstName: string,
  lastName: string,
  BPR_Status: string,
  phone: string,
): Promise<any> => {
  return httpApi
    .post<any>(
      `/userSso/userLogin?email=${email}&firstName=${firstName}&lastName=${lastName}&BPR_Status=${BPR_Status}&phone=${phone}`,
    )
    .then(({ data }) => data);
  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
