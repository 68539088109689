import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Row, Typography, Tooltip } from 'antd';
import { Link } from 'react-router-dom'; // Assuming you are using React Router
import { Collapse } from 'antd';
import { ReactI18NextChild } from 'react-i18next';
import styled from 'styled-components';
import { CheckSquareOutlined } from '@ant-design/icons';
import { Card } from 'antd';
// import { assignPagePermission, updatePagePermission } from '@app/api/permissions/AllPagePermisson.api';
import axios from 'axios';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
const { Title } = Typography;
const { Panel } = Collapse;
const Box = styled.ul`
  list-style-type: none;
`;
const Container = styled.div`
  width: 100%;
  margin: 40px auto;
`;
interface Permission {
  image: boolean;
  video: boolean;
  hide: boolean;
  show: boolean;
  filter: boolean;
  // Add more permissions as needed
}

interface Page {
  page_name: any;
  _id: string;
  name: string;
  checked: boolean;
  permissions: Permission;
}
// const pagesWithPermissions: Page[] = [
//   {
//     _id: '65f7d7afc79c894bb49636c1',
//     name: 'Your Subscriptions',
//     checked: false,
//     permissions: {
//       image: false,
//       video: false,
//       hide: false,
//       show: false,
//       filter: false,
//       // Add more permissions as needed
//     },
//   },
//   {
//     _id: '65f7d7afc79c894bb49636c9',
//     name: 'Trade Data',
//     checked: false,
//     permissions: {
//       image: false,
//       video: false,
//       hide: false,
//       show: false,
//       filter: false,
//       // Add more permissions as needed
//     },
//   },
//   {
//     _id: '65f7d7afc79c894bb49636cb',
//     name: 'Category Data',
//     checked: false,
//     permissions: {
//       image: false,
//       video: false,
//       hide: false,
//       show: false,
//       filter: false,
//       // Add more permissions as needed
//     },
//   },
//   // Repeat for other pages
// ];
const PermissionPage = ({ userid }: any) => {
  const [permissions, setPermissions] = useState<Page[]>();
  const [Pagedata, setPagedata] = useState<any>();
  const [loading, setLoading] = useState<any>();
  const token = useAppSelector((state) => state.auth.token);
  // async function assignPage() {
  //   if (userid) {
  //     try {
  //       const type = 2;
  //       const PageData = await assignPagePermission(userid, type);
  //       setPermissions(PageData.data?.pagePermissions);
  //       setPagedata(PageData.data);
  //     } catch (error) {}
  //   }
  // }
  async function assignPage() {
    if (userid) {
      setLoading(true);
      try {
        const type = 2;
        const PageData = await axios.get(`${process.env.REACT_APP_EC_URL}/assignPagePermission/${userid}/${type}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPermissions(PageData.data?.data?.pagePermissions);
        setPagedata(PageData.data?.data);
        setLoading(false);
      } catch (error) {}
    }
  }
  useEffect(() => {
    assignPage();
  }, []);

  //localhost:3000/localhost/assignPagePermission/addPermission
  const handleCheckboxChange = (pageIndex: number, permission: keyof Permission) => {
    const updatedPermissions = [...permissions];
    updatedPermissions[pageIndex].permissions[permission] = !updatedPermissions[pageIndex].permissions[permission];
    setPermissions(updatedPermissions);
  };
  const handleSelectAll = (pageIndex: number) => {
    const updatedPermissions = [...permissions];
    const pagePermissions = updatedPermissions[pageIndex].permissions;
    const isAllSelected = Object.values(pagePermissions).every((permission) => permission);
    updatedPermissions[pageIndex].permissions = Object.keys(pagePermissions).reduce((acc, key) => {
      acc[key as keyof Permission] = !isAllSelected;
      return acc;
    }, {} as Permission);

    setPermissions(updatedPermissions);
  };
  const handleSaveChanges = async () => {
    const updatedpermissions = {
      user_id: Pagedata.user_id,
      user_type: Pagedata.user_type,
      pagePermissions: [...permissions],
    };
    setLoading(true);
    try {
      // await updatePagePermission(updatedpermissions);
      await axios.post(`${process.env.REACT_APP_EC_URL}/assignPagePermission/addPermission`, updatedpermissions, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(false);
    } catch (error) {}
    assignPage();
  }; // Handle save changes logic here
  return (
    <Container>
      <Card title="Permissions" bordered={true}>
        {/* <Title level={2}>Permissions</Title> */}
        {loading ? (
          <div style={{ textAlign: 'center', margin: '20px' }}>
            <BaseSpin />
          </div>
        ) : (
          <>
            {' '}
            {permissions &&
              permissions?.map((page, index) => (
                <div key={page._id} style={{ margin: '50px 0' }}>
                  {page?.name === 'User' && (
                    <Title level={4} style={{ margin: '40px 0px' }}>
                      Admin Pages
                    </Title>
                  )}
                  {page?.name === 'Your Subscriptions' && (
                    <Title level={4} style={{ margin: '40px 0px' }}>
                      Dashboard Pages
                    </Title>
                  )}
                  <Row gutter={[16, 16]} justify="center">
                    <Col xs={24} lg={8}>
                      {page?.page_name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}
                    </Col>
                    <Col xs={24} lg={8}>
                      <Box>
                        {Object.entries(page.permissions).map(([key, value]) => (
                          <li key={key}>
                            <Checkbox
                              checked={value}
                              onChange={() => handleCheckboxChange(index, key as keyof Permission)}
                            >
                              {key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}
                            </Checkbox>
                          </li>
                        ))}
                      </Box>
                      <Tooltip placement="top" title="Seletet All">
                        <CheckSquareOutlined
                          onClick={() => handleSelectAll(index)}
                          // style={{ position: 'absolute', bottom: '0', fontSize: 'larger' }}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </div>
              ))}
          </>
        )}

        <div style={{ textAlign: 'center' }}>
          <Button type="primary" onClick={handleSaveChanges} loading={loading}>
            Save Changes
          </Button>
        </div>
      </Card>
    </Container>
  );
};

export default PermissionPage;
